/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Select,
  Steps,
  Checkbox,
} from 'antd';
import {
  Drawer,
  Input,
} from 'components/gyramais';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  DocumentExampleCNH,
  DocumentExampleRG,
  DocumentExampleIRPF1,
  DocumentExampleIRPF2,
  DocumentExampleIRPF3,
  DocumentExampleIRPF4,
  DocumentExampleIRPF5,
  DocumentExampleIRPF6,
  DocumentExampleIRPF7,
  DocumentExampleIRPF8,
  DocumentExampleIRPF9,
} from 'assets/images';
import { formatAndNotificateError, formatDate } from 'utils';
import { useConfirmModalContext } from 'contexts/ConfirmModalContext/confirm-modal-context';
import { TermCard, UploadCard } from 'components';
import {
  SAVE_BUSINESS_USER,
  BUSINESS,
  CIVIL_STATUSES,
  SAVE_ADDRESS,
  POSTAL_CODE_TO_ADDRESS,
  GET_USER_BY_CPF,
  COMPLETE_BUSINESS_USER,
} from './graphql';
import { badesulMicrocreditoPartnerFormItems } from './inputs';
import './styles.less';

const { Item } = Form;
const { Option } = Select;
const { Step } = Steps;

const getContext = (type) => {
  const contexts = {
    socio: 'sócio(a)',
    avalista: 'avalista',
    representantelegal: 'representante legal',
  };

  const context = contexts[type];

  return context ?? 'responsável';
};

const steps = {
  CREATE_NEW_USER: 0,
  COMPLETED: 1,
};

const sizeMap = {
  small: {
    xs: 24, sm: 24, md: 24, lg: 8,
  },
  normal: {
    xs: 24, sm: 24, md: 24, lg: 16,
  },
  half: {
    xs: 24, sm: 24, md: 24, lg: 12,
  },
  large: {
    xs: 24, sm: 24, md: 24, lg: 24,
  },
};
const { useForm } = Form;

const alerts = {
  idCard: (
    <>
      O documento deve estar na validade e a foto em boa
      qualidade. Envie apenas <b>UMA FOTO</b> do documento aberto.
      Visualize o exemplo para instruções mais detalhadas.
    </>
  ),
  proofOfIncome: (
    <>
      Comprovante de renda (holerite, IRPF, DECORE, extrato do INSS,
      declaração de renda de autônomo com assinatura reconhecida em cartório)
    </>
  ),
  proofOfIncomeReceipt: (
    <>
      Recibo da Declaração Imposto de Renda. Caso não tenha
      declarado, envie recibo de comprovante de{' '}
      <b>NÃO DECLARANTE DE IMPOSTO DE RENDA</b>.
    </>
  ),
  proofOfAddress: (
    <>
      Comprovante de Endereço atualizado (emissão de até 120 dias):
      Serão aceitos como documentos de comprovação de residência:
      Contas de Concessionárias de Serviços Públicos (Luz, água, gás);
      Contas de Telefonia fixo ou móvel; Serviço de TV por assinatura ou Internet Banda Larga;
      Boleto de cobrança de condomínio; Fatura de cartão de crédito;
      Demonstrativo ou comunicado do Instituto Nacional do
      Seguro Social (INSS) ou da Receita Federal do Brasil (RFB);
      Extrato do Fundo de Garantia do Tempo de Serviço (FGTS)
    </>
  ),
};

const fileExamples = {
  idCard: [
    DocumentExampleRG,
    DocumentExampleCNH,
  ],
  proofOfIncome: [
    DocumentExampleIRPF1,
    DocumentExampleIRPF2,
    DocumentExampleIRPF3,
    DocumentExampleIRPF4,
    DocumentExampleIRPF5,
    DocumentExampleIRPF6,
    DocumentExampleIRPF7,
    DocumentExampleIRPF8,
    DocumentExampleIRPF9,
  ],
};

const NewBadesulForm = ({
  visible,
  onClose,
  businessId,
  businessUser: selectedBusinessUserPointer,
  type,
}) => {
  const { requestConfirmation } = useConfirmModalContext();

  const [form] = useForm();
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [fullStep, setFullStep] = useState(badesulMicrocreditoPartnerFormItems[0]);
  const [creatingCivilPartner, setCreatingCivilPartner] = useState(false);

  const resetFields = () => {
    form.resetFields();
    setFullStep(badesulMicrocreditoPartnerFormItems[0]);
    setCreatingCivilPartner(false);
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const {
    data: {
      business = {},
    } = {},
    loading: businessLoading,
    refetch,
  } = useQuery(BUSINESS, {
    variables: { id: businessId },
    skip: !businessId,
  });

  const [
    getUserByCpf,
    { loading: userByCpfLoading },
  ] = useMutation(GET_USER_BY_CPF);

  const {
    data: {
      civilStatuses = [],
    } = {},
    loading: civilStatusesLoading,
  } = useQuery(CIVIL_STATUSES);

  const [postalCodeToAddress, { loading }] = useMutation(POSTAL_CODE_TO_ADDRESS, {
    onError: (error) => formatAndNotificateError(error),
    onCompleted: ({ postalCodeToAddress }) => {
      if (postalCodeToAddress) {
        const {
          street,
          district,
          city,
          state,
        } = postalCodeToAddress;

        form.setFieldsValue({
          address_street: street,
          address_district: district,
          address_city: city,
          address_state: state,
        });
      }

      return true;
    },
  });

  const [
    saveBusinessUserMutation,
    {
      loading: saveBusinessLoading,
    },
  ] = useMutation(SAVE_BUSINESS_USER, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    saveAddress,
    {
      loading: saveAddressLoading,
    },
  ] = useMutation(SAVE_ADDRESS, {
    onError: (error) => formatAndNotificateError(error),
  });

  const [
    completeBusinessUser,
    {
      loading: completeBusinessLoading,
    },
  ] = useMutation(COMPLETE_BUSINESS_USER, {
    onError: (error) => formatAndNotificateError(error),
    onCompleted: ({ completeBusinessUser }) => {
      if (completeBusinessUser) {
        refetch();
        handleClose();
      }
    },
  });

  const selectedBusinessUser = useMemo(() => {
    const users = business?.users;

    if (!users) return null;
    if (!selectedBusinessUserPointer?.id) return null;

    return users.find((user) => user.id === selectedBusinessUserPointer?.id);
  }, [selectedBusinessUserPointer, business]);

  useEffect(() => {
    if (!selectedBusinessUser) {
      form.setFieldsValue({
        cpf: null,
        typesValues: [...new Set(['avalista'].concat(selectedBusinessUser?.types || []))],
      });
      return;
    }

    const email = selectedBusinessUser?.user?.email;
    const isEmail = email?.includes('@');

    const partnerData = {};
    for (const key in selectedBusinessUser?.user?.civilPartner ?? {}) {
      if (Object.hasOwn(selectedBusinessUser?.user?.civilPartner, key)) {
        partnerData[`partner_${key}`] = selectedBusinessUser.user.civilPartner[key];
      }
    }
    for (const key in selectedBusinessUser?.user?.address ?? {}) {
      if (Object.hasOwn(selectedBusinessUser?.user?.address, key)) {
        partnerData[`address_${key}`] = selectedBusinessUser.user.address[key];
      }
    }

    form.setFieldsValue({
      ...partnerData,
      ...selectedBusinessUser.user,
      ...selectedBusinessUser.user?.additionalInfo,
      birthDate: selectedBusinessUser.user?.birthDate
        && formatDate(new Date(selectedBusinessUser.user?.birthDate)),
      email: isEmail ? email : '',
      typesValues: selectedBusinessUser.types?.filter((type) => !['conjuge'].includes(type.value)).map((type) => type.value).sort(),
      civilStatusId: selectedBusinessUser.user?.civilStatus?.id,
      idCardFileId: selectedBusinessUser.user?.idCard?.id,
      proofOfMariageOrDivorceFileId: selectedBusinessUser?.user?.proofOfMariageOrDivorce?.id,
    });
  }, [selectedBusinessUser]);

  const mutations = {
    saveBusinessUser: {
      mutation: saveBusinessUserMutation,
      baseVariables: () => ({
        id: selectedBusinessUser?.id,
        businessId,
      }),
    },
    saveAddress: {
      mutation: saveAddress,
      baseVariables: () => ({
        id: selectedBusinessUser?.user?.address?.id,
        userId: selectedBusinessUser?.user?.id,
      }),
    },
    completeBusinessUser: {
      mutation: completeBusinessUser,
      baseVariables: () => ({
        id: selectedBusinessUser?.id,
      }),
    },
  };

  const inputsFromDatabase = {
    civilStatuses,
  };

  const handleSubmit = async () => {
    setLoadingMutation(true);
    try {
      await form.validateFields();
      const mutationName = await fullStep.mutationName;
      const { mutation, baseVariables } = mutations[mutationName];

      const variables = {
        ...baseVariables(),
        ...form.getFieldsValue(),
      };

      const civilPartnerData = {};
      for (const key in variables) {
        if (Object.hasOwn(variables, key)) {
          if (key.includes('partner_')) {
            const [, realKey] = key.split('partner_');
            civilPartnerData[realKey] = variables[key];
          }

          if (key.includes('address_')) {
            const [, realKey] = key.split('address_');
            variables[realKey] = variables[key];
          }
        }
      }

      if (Object.keys(civilPartnerData)[0]) {
        variables.civilPartner = civilPartnerData;
      }

      if (fullStep.key === 'partner' && !creatingCivilPartner) {

        const {
          data: {
            userByCPF,
          },
        } = await getUserByCpf({ variables: { cpf: civilPartnerData.cpf } });

        if (!userByCPF) {
          setCreatingCivilPartner(true);
          return;
        }

        variables.civilPartner.id = userByCPF.id;
      }

      const response = await mutation({ variables });

      const nextStep = fullStep.handleGetNextStep(response.data.saveBusinessUser);
      setFullStep(badesulMicrocreditoPartnerFormItems.find((item) => item.key === nextStep));
    } finally {
      setLoadingMutation(false);
    }
  };

  const isLoading = loadingMutation || saveBusinessLoading || businessLoading
    || civilStatusesLoading || saveAddressLoading || userByCpfLoading || completeBusinessLoading;

  const stepsToShow = badesulMicrocreditoPartnerFormItems
    .filter((step) => step.showStep(selectedBusinessUser));

  return (
    <Drawer
      visible={visible}
      className={`business-user-drawer ${visible ? 'active' : ''}`}
      maxWidth="71.5rem"
      closable={!isLoading}
      destroyOnClose
      onClose={() => {
        if (!selectedBusinessUser) {
          requestConfirmation({
            onOk: () => {
              handleClose();
            },
            onCancel: () => { },
          });
        } else {
          handleClose();
        }
      }}
      title={fullStep.key !== steps.COMPLETED && `${selectedBusinessUser?.id ? 'Editar' : 'Adicionar'} ${getContext(type)}`}
      backButton={fullStep.handleGetLastStep(selectedBusinessUser) && {
        visible: true,
        disabled: isLoading,
        onClick: () => {
          const lastStepKey = fullStep.handleGetLastStep(selectedBusinessUser);

          setFullStep(
            badesulMicrocreditoPartnerFormItems.find((form) => form.key === lastStepKey),
          );
        },
      }}
      primaryButton={{
        id: 'continue-business-user-button',
        loading: isLoading,
        onClick: handleSubmit,
      }}
    >
      <div className="carousel-content">
        <div>
          <Steps
            className="steps"
            current={stepsToShow.indexOf(fullStep)}
            direction="horizontal"
          >
            {stepsToShow
              .map((step) => (
                <Step key={step.key} title={step.name} />
              ))}
          </Steps>

          <div className="steps-content">
            <div className="drawer-body">
              <Form
                layout="vertical"
                form={form}
                className="new-partner-form"
              >
                <Row gutter={12}>
                  {fullStep
                    .inputs
                    .filter((input) => {
                      if (!input.showIf) {
                        return true;
                      }

                      const shouldShowInput = input.showIf(selectedBusinessUser, {
                        creatingCivilPartner,
                      });

                      return shouldShowInput;
                    })
                    .map((input) => {
                      const isRequired = input.notRequired !== true;

                      if (input.divider) {
                        return (
                          <Col {...sizeMap.large}>
                            <hr />
                          </Col>
                        );
                      }

                      if (input.type === 'checkbox') {
                        return (
                          <Col {...sizeMap.large}>
                            <TermCard
                              type={selectedBusinessUser?.user?.civilStatus?.value}
                              alert="A seguir, você assinará um termo que comprova a veracidade do estado civil do sócio. Leia atentamente."
                              user={selectedBusinessUser?.user}
                              business={business}
                              address={selectedBusinessUser?.user?.address}
                            />
                            <Item
                              name={input.value}
                              valuePropName="checked"
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (value) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error('Você precisa concordar com o termo'),
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Checkbox loading={loading}>
                                {input.text}
                              </Checkbox>

                            </Item>
                          </Col>
                        );
                      }

                      if (input.size === 'document') {
                        return (
                          <Col {...sizeMap.large}>
                            <Item
                              name={input.value}
                              rules={isRequired ? [
                                {
                                  required: true,
                                  message: `Insira o ${input.name}`,
                                },
                              ] : []}
                            >
                              <UploadCard
                                id="idCardFileId"
                                accept="image/png,image/jpeg,application/pdf"
                                label={input.name}
                                exampleImages={fileExamples[input.value]}
                                alert={alerts[input.value]}
                                actualFile={selectedBusinessUser?.user?.[input.value]}
                                onCompleted={async ({ id }) => {
                                  const { mutation, baseVariables } = mutations.saveBusinessUser;

                                  const variables = baseVariables();

                                  variables[`${input.value}FileId`] = id;

                                  mutation({
                                    variables,
                                  });

                                  const obj = {};
                                  obj[input.value] = id;
                                  await form.setFieldsValue(obj);
                                }}
                                outsideLoading={loading}
                              />
                            </Item>

                          </Col>
                        );
                      }

                      return (
                        <Col {...sizeMap[input.size]}>
                          <Item
                            name={input.value}
                            rules={
                              isRequired ? [
                                {
                                  required: true,
                                  message: `Por favor preencha o campo ${input.name.toLowerCase()}.`,
                                },
                              ] : []
                            }
                            label={input.name}
                          >
                            {(input.options || input.optionsFromDatabase) ? (
                              <Select
                                className="gyramais-input"
                                placeholder={input.name}
                                loading={loading}
                              >
                                {input.options
                                  ? input.options.map((option) => (
                                    <Option value={option}>{option}</Option>
                                  ))
                                  : inputsFromDatabase[input.optionsFromDatabase].map((option) => (
                                    <Option value={option.id}>{option.name}</Option>
                                  ))}
                              </Select>
                            ) : (
                              <Input
                                loading={loading}
                                type={input.maskType ? 'masked' : input.type}
                                disabled={input.disabled}
                                className="ant-input"
                                guide
                                mask={input.maskType}
                                placeholder={input.name}
                                onKeyUp={(e) => {
                                  if (input.value === 'address_postalCode') {

                                    const postalCode = e.target.value.replace(/[_-\s]/g, '');
                                    if (postalCode.replace(/[_-\s]/g, '').length === 8 && !loading) {
                                      postalCodeToAddress({ variables: { postalCode } });
                                    }
                                  }
                                }}
                              />
                            )}
                          </Item>
                        </Col>
                      );
                    })}
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default NewBadesulForm;
