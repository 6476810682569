// @ts-check;
import React, { useEffect } from 'react';
import {
  Form, Row, Col, Divider, Select,
} from 'antd';
import {
  Input,
} from 'components/gyramais';
import {
  formatString,
} from 'utils';
import {
  AddressForm,
} from 'components';

const { Option } = Select;

const { Item } = Form;

/**
 * @type {{
 *  name:string;
 *  value:string;
 *  requiredMessage?:string;
 *  maskType?: "cnpj" | "phone";
 *  type?:"currency";
 *  size: "1" | "2" | "3";
 *  options: string[];
 * }[]}
 */
const inputs = [
  {
    name: 'CNPJ',
    value: 'cnpj',
    size: 'small',
    maskType: 'cnpj',
    disabled: true,
  },
  {
    size: 'normal',
    name: 'Nome fantasia',
    value: 'name',
  },
  {
    size: 'normal',
    name: 'Razão Social',
    value: 'legalName',
  },
  {
    name: 'Contato',
    maskType: 'phone',
    value: 'phoneNumber',
    size: 'small',
  },
  {
    name: 'Capital Social',
    value: 'socialCapital',
    size: 'small',
    type: 'currency',
  },
  {
    size: 'normal',
    name: 'Tipo de capital',
    value: 'capitalType',
    options: [
      'Nacional Sem Participação Estrangeira',
      'Nacional com Participação Estrangeira',
      'Nacional com participação estrangeira superior ou igual a 50% capital votante',
      'Nacional com participação estrangeira inferior a 50% capital votante',
    ],
  }, {
    size: 'large',
    name: 'Natureza Jurídica',
    value: 'legalNature',
    options: [
      'Associação Privada',
      'Associação Pública',
      'Autarquia Estadual ou do Distrito Federal',
      'Autarquia Federal',
      'Autarquia Municipal',
      'Candidato a Cargo Político Eletivo',
      'Clube/Fundo de Investimento',
      'Comissão de Conciliação Prévia',
      'Comissão Polinacional',
      'Comunidade Indígena',
      'Condomínio Edilício',
      'Consórcio de Empregadores',
      'Consórcio de Sociedades',
      'Consórcio Simples',
      'Contribuinte Individual',
      'Cooperativa',
      'Empresa Binacional',
      'Empresa Domiciliada no Exterior',
      'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
      'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
      'Empresa Individual Imobiliária',
      'Empresa Pública',
      'Empresário (Individual)',
      'Entidade de Mediação e Arbitragem',
      'Entidade Sindical',
      'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
      'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras',
      'Estabelecimento, no Brasil, de Sociedade Estrangeira',
      'Fundação Estadual ou do Distrito Federal',
      'Fundação Federal',
      'Fundação Municipal',
      'Fundação ou Associação domiciliada no exterior',
      'Fundação Privada',
      'Fundo Privado',
      'Fundo Público',
      'Grupo de Sociedades',
      'Organização Internacional',
      'Organização Religiosa',
      'Órgão Público Autônomo Estadual ou do Distrito Federal',
      'Órgão Público Autônomo Federal',
      'Órgão Público Autônomo Municipal',
      'Órgão Público do Poder Executivo Estadual ou do Distrito Federal',
      'Órgão Público do Poder Executivo Federal',
      'Órgão Público do Poder Executivo Municipal',
      'Órgão Público do Poder Judiciário Estadual',
      'Órgão Público do Poder Judiciário Federal',
      'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal',
      'Órgão Público do Poder Legislativo Federal',
      'Órgão Público do Poder Legislativo Municipal',
      'Outras Instituições Extraterritoriais',
      'Partido Político',
      'Representação Diplomática Estrangeira',
      'Serviço Notarial e Registral (Cartório)',
      'Serviço Social Autônomo',
      'Sociedade Anônima Aberta',
      'Sociedade Anônima Fechada',
      'Sociedade de Economia Mista',
      'Sociedade em Conta de Participação',
      'Sociedade Empresária em Comandita por Ações',
      'Sociedade Empresária em Comandita Simples',
      'Sociedade Empresária em Nome Coletivo',
      'Sociedade Empresária Limitada',
      'Sociedade Simples em Comandita Simples',
      'Sociedade Simples em Nome Coletivo',
      'Sociedade Simples Limitada',
      'Sociedade Simples Pura',
    ],
  },
];

const sizeMap = {
  small: {
    xs: 24, sm: 24, md: 24, lg: 8,
  },
  normal: {
    xs: 24, sm: 24, md: 24, lg: 16,
  },
  large: {
    xs: 24, sm: 24, md: 24, lg: 24,
  },
};

const BadesulMicrocreditoBusinessForm = ({
  business,
  address,
  form,
  flow,
}) => {
  useEffect(() => {
    if (!business.id) {
      return;
    }
    const formObj = { id: business.id };

    const inputKeys = inputs.map((input) => input.value);
    for (const inputKey of inputKeys) {
      let value = business[inputKey] ?? business.additionalInfo?.[inputKey];

      if (inputKey === 'cnpj') {
        value = formatString(value, 'cnpj');
      }
      if (inputKey === 'phoneNumber') {
        value = formatString(value, 'phone');
      }

      formObj[inputKey] = value;
    }

    form.setFieldsValue(formObj);

  }, [business?.id]);

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Row wrap gutter={12}>
        {inputs.map((input) => (
          <Col {...sizeMap[input.size]}>
            <Item
              name={input.value}
              rules={[{ required: true, message: `Por favor preencha o campo ${input.name.toLowerCase()}.` }]}
            >
              {input.options ? (
                <Select
                  className="gyramais-input"
                  placeholder={input.name}
                >
                  {input.options.map((option) => (
                    <Option value={option}>{option}</Option>
                  ))}
                </Select>
              ) : (
                <Input
                  type={input.maskType ? 'masked' : input.type}
                  disabled={input.disabled}
                  className="ant-input"
                  guide
                  mask={input.maskType}
                  placeholder={input.name}
                />
              )}
            </Item>
          </Col>
        ))}

        <Divider className="divider-without-margin" />

        <AddressForm
          address={address}
          form={form}
          flow={flow}
          onlyColumns
          fieldProps={{
            postalCode: {
              fieldOrder: 1,
              lg: 7,
              md: 12,
              xs: 24,
            },
            street: {
              fieldOrder: 2,
              lg: 11,
              md: 12,
              xs: 24,
            },
            district: {
              fieldOrder: 3,
              lg: 6,
              md: 12,
              xs: 24,
            },
            number: {
              fieldOrder: 4,
              lg: 5,
              md: 12,
              xs: 24,
            },
            complement: {
              fieldOrder: 5,
              lg: 7,
              md: 12,
              xs: 24,
            },
            state: {
              fieldOrder: 6,
              lg: 6,
              md: 12,
              xs: 24,
            },
            city: {
              fieldOrder: 7,
              lg: 6,
              md: 12,
              xs: 24,
            },
          }}
          business
        />
      </Row>
    </Form>
  );
};

export default BadesulMicrocreditoBusinessForm;
export {
  inputs,
};
