// igual ao core/src/components/modals/EditUserModal/Tabs/badesulInputs.js
// @ts-check

import {
  badesulRole,
  documentOptions,
  educationOptions,
  maritalStatus,
  nationality,
  occupation,
  pepOptions,
  race,
} from './inputOptions';

/**
 * @typedef  {{
 *  name:string;
 *  value:string;
 *  maskType?: "cnpj" | "phone" | "cpf" | "date" | "cep";
 *  type?: "checkbox";
 *  size: "small" | "normal" | "large" | "half" | "document";
 *  options?: string[];
 *  optionsFromDatabase?: string;
 *  showIf?: (user:any, additionalData?: any) => boolean
 *  text?:string
 *  withCustomRender?: string
 *  notRequired?: boolean
 * }} input
 */

const getCivilStatusValue = (businessUser) => {
  const civilStatus = businessUser?.user?.civilStatus?.parent?.value
    ?? businessUser?.user?.civilStatus?.value;

  return civilStatus;
};

/**
 * @typedef {{
 *  divider: boolean
 * }} divider
 */

/**
 * @type {{
 *  key: string,
 *  name: string,
 *  showStep: (businessUser: any)=> boolean
 *  handleGetNextStep: (businessUser: any) => string
 *  handleGetLastStep: (businessUser: any) => string,
 *  inputs: (input | divider)[]
 *  mutationName: string,
 * }[]}
 */
const badesulMicrocreditoPartnerFormItems = [
  {
    key: 'data',
    name: 'Dados do(a) sócio',
    inputs: [
      {
        size: 'half',
        value: 'fullName',
        name: 'Nome completo',
      },
      {
        size: 'half',
        value: 'cpf',
        name: 'CPF',
        maskType: 'cpf',
      },
      {
        size: 'half',
        name: 'Nome da mãe',
        value: 'motherName',
      },
      {
        size: 'half',
        name: 'Nome do pai',
        value: 'fatherName',
      },
      {
        size: 'small',
        value: 'civilStatusId',
        name: 'Estado Civil',
        optionsFromDatabase: 'civilStatuses',
      },
      { divider: true },
      {
        size: 'large',
        name: 'Escolaridade',
        value: 'education',
        options: educationOptions,
      },
      {
        size: 'small',
        name: 'Papeis',
        value: 'badesulRole',
        options: badesulRole,
      },
      {
        size: 'normal',
        name: 'Raça',
        value: 'race',
        options: race,
      },
      {
        size: 'half',
        name: 'Profissão',
        value: 'occupation',
        options: occupation,
      },
      {
        size: 'half',
        name: 'Nacionalidade',
        value: 'nationality',
        options: nationality,
      },
      {
        size: 'large',
        name: 'PEP',
        value: 'pep',
        options: pepOptions,
      },
      {
        size: 'large',
        name: 'Familiar PEP',
        value: 'familiaryPep',
        options: pepOptions,
      },
      {
        divider: true,
      },
      {
        size: 'half',
        name: 'Tipo de documento',
        value: 'documentType',
        options: documentOptions,
      },
      {
        size: 'half',
        name: 'Número documento',
        value: 'documentNumber',
      },
      {
        size: 'half',
        name: 'Órgão Emissor',
        value: 'documentIssuingBody',
      },
      {
        size: 'half',
        name: 'Data de Emissão',
        value: 'documentDate',
        maskType: 'date',
      },
    ],
    showStep: () => true,
    handleGetLastStep: () => undefined,
    handleGetNextStep: (businessUser) => {
      const civilStatusValue = getCivilStatusValue(businessUser);

      if (civilStatusValue === 'casado') {
        return 'partner';
      }

      return 'address';
    },
    mutationName: 'saveBusinessUser',
  },
  {
    key: 'partner',
    name: 'Cônjuge',
    handleGetLastStep: () => 'data',
    showStep: (businessUser) => {
      const civilStatusValue = getCivilStatusValue(businessUser);
      return civilStatusValue === 'casado';
    },
    handleGetNextStep: () => 'address',
    inputs: [
      {
        size: 'half',
        name: 'CPF',
        value: 'partner_cpf',
        maskType: 'cpf',
      },
      {
        size: 'half',
        name: 'Nome',
        value: 'partner_fullName',
        showIf: (businessUser, { creatingCivilPartner } = {}) => {
          if (businessUser?.user?.civilPartner?.id) {
            return true;
          }
          if (creatingCivilPartner) {
            return true;
          }

          return false;
        },
      },
      {
        size: 'half',
        name: 'Email',
        value: 'partner_email',
        showIf: (businessUser, { creatingCivilPartner } = {}) => {
          if (businessUser?.user?.civilPartner?.id) {
            return true;
          }
          if (creatingCivilPartner) {
            return true;
          }

          return false;
        },
      },
      {
        size: 'half',
        name: 'Regime de Bens',
        value: 'maritalStatus',
        showIf: (businessUser, { creatingCivilPartner } = {}) => {
          if (businessUser?.user?.civilPartner?.id) {
            return true;
          }
          if (creatingCivilPartner) {
            return true;
          }
          return false;
        },

        options: maritalStatus,
      },
    ],
    mutationName: 'saveBusinessUser',
  },
  {
    key: 'address',
    name: 'Endereço',
    showStep: () => true,
    handleGetLastStep: (businessUser) => {
      const civilStatusValue = getCivilStatusValue(businessUser);

      if (civilStatusValue === 'casado') {
        return 'partner';
      }

      return 'data';
    },
    handleGetNextStep: () => 'documents',
    inputs: [
      {
        size: 'half',
        value: 'address_postalCode',
        name: 'CEP',
        maskType: 'cep',
      },
      {
        size: 'half',
        value: 'address_district',
        name: 'Bairro',
      },
      {
        size: 'half',
        value: 'address_street',
        name: 'Rua',
      },
      {
        size: 'half',
        value: 'address_number',
        name: 'Número',
      },
      {
        size: 'half',
        value: 'address_complement',
        name: 'Complemento',
        notRequired: true,
      },
      {
        size: 'half',
        value: 'address_state',
        name: 'Estado',
      },
      {
        size: 'half',
        value: 'address_city',
        name: 'Cidade',
      },
    ],
    mutationName: 'saveAddress',
  },
  {
    key: 'documents',
    name: 'Documentos',
    showStep: () => true,
    handleGetNextStep: () => 'documents',
    handleGetLastStep: () => 'address',
    inputs: [
      {
        size: 'document',
        value: 'idCard',
        name: 'Identidade, CPF ou CNH',
      },
      {
        size: 'document',
        value: 'proofOfIncome',
        name: 'Comprovante de Renda',
      },
      {
        size: 'document',
        value: 'proofOfAddress',
        name: 'Comprovante de Endereço',
      },
      {
        size: 'document',
        value: 'partnerIdCard',
        name: 'Identidade do Cônjuge, CPF ou CNH',
        showIf: (businessUser) => getCivilStatusValue(businessUser) === 'casado',
      },
      {
        size: 'document',
        value: 'proofOfMariageOrDivorce',
        name: 'Certidão de casamento',
        showIf: (businessUser) => getCivilStatusValue(businessUser) === 'casado' && businessUser?.user.civilStatus.value !== 'uniao-estavel',
      },
      {
        size: 'document',
        value: 'proofOfMariageOrDivorce',
        name: 'Averbação da separação ou divórcio',
        showIf: (businessUser) => businessUser?.user.civilStatus.value.includes('divorciado') || businessUser?.user.civilStatus.value.includes('separado') || businessUser?.user.civilStatus.value === 'desquitado',
      },
      {
        size: 'large',
        value: 'withProof',
        name: 'Termo de estado civil',
        type: 'checkbox',
        showIf: (businessUser) => getCivilStatusValue(businessUser) === 'solteiro',
        text: `Confirmo que li, aceito e concordo com as informações
descritas acima. Reconheço a assinatura deste termo como
válida e comprovante do estado civil do sócio adicionado.`,
        withCustomRender: 'TermCard',
      },
    ],
    mutationName: 'completeBusinessUser',
  },
];

export {
  badesulMicrocreditoPartnerFormItems,
};
