// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-partner-form {
  padding-bottom: 8rem;
}
.new-partner-form .divider {
  margin-bottom: 1.75rem;
}
.new-partner-form .civil-status-col {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .new-partner-form .civil-status-col {
    margin-bottom: 1.5rem;
  }
}
.new-partner-form .civil-status-col > div {
  flex: 1;
}
.new-partner-form .civil-status-col > span {
  padding-left: 1rem;
  padding-right: 0rem;
}
.new-partner-form .civil-status-col > span svg {
  width: 1.5rem;
  height: 1.5rem;
}
.new-partner-form .civil-status-col > span.information-icon svg {
  color: var(--primary-color);
}
.new-partner-form .civil-status-col > span.warning-icon svg {
  color: var(--primary-button-color);
}
.new-partner-form .civil-status-col .ant-form-item {
  margin-bottom: 0rem !important;
}
.new-partner-form .ant-form-item-label label {
  color: black;
}
.new-partner-form .civil-status-col > span {
  padding-left: 0rem;
  padding-right: 1rem;
}
@media (max-width: 768px) {
  .new-partner-form .civil-status-col {
    flex-direction: row-reverse;
  }
  .new-partner-form .civil-status-col > span {
    padding-left: 1rem;
    padding-right: 0rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/BusinessBadesulMicrocreditoRegisterScreen/components/BadesulMicrocreditoBusinessUserDrawer/styles.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAFA;EAGI,sBAAA;AAEJ;AALA;EAOI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EAAA;IACE,qBAAA;EAEJ;AACF;AAfA;EAgBM,OAAA;AAEN;AAlBA;EAoBM,kBAAA;EACA,mBAAA;AACN;AAtBA;EAwBQ,aAAA;EACA,cAAA;AACR;AAEM;EAEI,2BAAA;AADV;AAIM;EAEI,kCAAA;AAHV;AAhCA;EAyCM,8BAAA;AANN;AAnCA;EA8CI,YAAA;AARJ;AAtCA;EAmDM,kBAAA;EACA,mBAAA;AAVN;AAcI;EAAA;IACE,2BAAA;EAXJ;EAUE;IAII,kBAAA;IACA,mBAAA;EAXN;AACF","sourcesContent":[".new-partner-form {\n  padding-bottom: 8rem;\n  .divider {\n    margin-bottom: 1.75rem;\n  }\n\n  .civil-status-col {\n    display: flex;\n    align-items: center;\n    margin-bottom: 2rem;\n\n    @media(max-width: @md-screen) {\n      margin-bottom: 1.5rem;\n    }\n\n    > div {\n      flex: 1;\n    }\n    \n    > span {\n      padding-left: 1rem;\n      padding-right: 0rem;\n\n      svg {\n        width: 1.5rem;\n        height: 1.5rem;\n      }\n\n      &.information-icon{\n        svg {\n          color: var(--primary-color);\n        }\n      }\n      &.warning-icon{\n        svg {\n          color: var(--primary-button-color);\n        }\n      }\n    }\n\n    .ant-form-item {\n      margin-bottom: 0rem !important;\n    }\n  }\n\n  .ant-form-item-label label{\n    color: black;\n  }\n\n  .civil-status-col {\n    > span {\n      padding-left: 0rem;\n      padding-right: 1rem;\n    }\n\n    // inverte o lado do icone para esquerda\n    @media(max-width: @md-screen) {\n      flex-direction: row-reverse;\n\n      > span {\n        padding-left: 1rem;\n        padding-right: 0rem;\n      }\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
