const documentList = [
  {
    documentValue: 'cartaoCNPJ',
    documentName: 'cartaoCNPJFileId',
    label: 'Cartão CNPJ',
  },
  {
    documentValue: 'qsa',
    documentName: 'qsaFileId',
    label: 'QSA',
    alertMessage: 'Quadro de sócios e administradores',
  },
  {
    documentValue: 'cadin',
    documentName: 'cadinFileId',
    label: 'CADIN/RS',
    alertMessage: 'Comprovação de que a empresa está em dia com o Cadastro Informativo de Créditos não Quitados do Setor Público Federal',
  },
  {
    documentValue: 'stateCnd',
    documentName: 'stateCndFileId',
    label: 'CND Estadual',
    alertMessage: 'Comprovação de que a empresa está em dia com o estado do Rio Grande do Sul',
  },
  {
    documentValue: 'pgfn',
    documentName: 'pgfnFileId',
    label: 'PGFN',
    alertMessage: 'Comprovação de que a empresa está em dia com a Procuradoria-Geral da Fazenda Nacional ',
  },
  {
    documentValue: 'cndTrabalhista',
    documentName: 'cndTrabalhistaFileId',
    label: 'CND Trabalhista',
    alertMessage: 'Comprovação de que a empresa está em dia com os direitos trabalhistas',
  },
];

export { documentList };
