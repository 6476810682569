/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Input,
  Skeleton,
  Tooltip,
  InputNumber,
} from 'antd';
import {
  InfoCircleFilled,
} from '@ant-design/icons';
import MaskedTextInput from 'react-text-mask';
import { formatCurrency } from 'utils';

import './styles.less';

const { TextArea } = Input;

const masks = {
  phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  emailOrCpf: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '.', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '.', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
  cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

/**
 * @type {React.FC<import('antd').InputProps & {
 *  alert?: string
 *  mask?: 'phone' | 'cpf' | 'cnpj' | 'cep' | 'emailOrCpf' |'date' | RegExp[]
 *  loading?: boolean
 *  addonBefore?: React.ReactNode | string
 *  type?: 'masked' | 'password' | 'currency' | 'textarea'
 *  guide?: boolean
 *  maxLength?: number
 * }>}
 */
const GyramaisInput = ({
  type,
  className,
  loading,
  mask,
  alert,
  addonBefore,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const getMask = () => {
    if (masks[mask]) {
      return masks[mask];
    }
    return mask;
  };

  const isMasked = mask || type === 'masked';
  const isNormalInput = !isMasked && ['text', 'number', 'string', false, undefined].includes(type);
  const isTextArea = type === 'textarea';

  return (
    <>
      {loading ? (
        <Skeleton.Button
          shape="square"
          active
          className="gyramais-input-skeleton"
          size="large"
        />
      ) : (
        <>
          {isNormalInput && (
            <Input
              className={`${className || ''} gyramais-input`}
              onFocus={() => setVisible(true)}
              onBlur={() => setVisible(false)}
              addonBefore={addonBefore}
              suffix={alert && visible && (
                <Tooltip title={alert} visible={visible}>
                  <InfoCircleFilled className="input-info-icon" />
                </Tooltip>
              )}
              {...props}
            />
          )}

          {isTextArea && (
            <TextArea
              className={`${className || ''} gyramais-input`}
              onFocus={() => setVisible(true)}
              onBlur={() => setVisible(false)}
              suffix={alert && visible && (
                <Tooltip title={alert} visible={visible}>
                  <InfoCircleFilled className="input-info-icon" />
                </Tooltip>
              )}
              {...props}
            />
          )}

          {type === 'password' && (
            <Input.Password
              className={`${className || ''} gyramais-input`}
              {...props}
            />
          )}

          {isMasked && (
            <div>
              <MaskedTextInput
                className="ant-input gyramais-input"
                mask={getMask()}
                onFocus={() => setVisible(true)}
                onBlur={() => setVisible(false)}
                {...props}
              />
              {alert && visible && (
                <div className="custom-input-tooltip">
                  <Tooltip title={alert} visible={visible}>
                    <InfoCircleFilled className="input-info-icon" />
                  </Tooltip>
                </div>
              )}
            </div>
          )}

          {type === 'currency' && (
            <InputNumber
              formatter={(e) => {
                if (!e) {
                  return '';
                }
                return formatCurrency(e);
              }}
              onFocus={(e) => {
                e.target.setSelectionRange(e.target.value.length, e.target.value.length);
              }}
              step={0.01}
              parser={(e) => {
                const value = e.replace(/\D/g, '');
                const intNumberDigits = value.toString().slice(0, value.toString().length - 2);
                const lastTwoDigits = value.toString().substr(value.toString().length - 2);

                return `${intNumberDigits || '0'}.${lastTwoDigits.length === 1 ? `0${lastTwoDigits}` : lastTwoDigits || '00'}`;
              }}
              className={`gyramais-input ${className || ''}`}
              {...props}
            />
          )}
          {type === 'integer' && (
            <InputNumber
              formatter={(e) => {
                if (!e) {
                  return '';
                }
                return e;
              }}
              parser={(e) => {
                const value = e.replace('.', '');
                return value;
              }}
              type="number"
              precision={0}
              className={`gyramais-input ${className || ''}`}
              {...props}
            />
          )}
        </>
      )}
    </>
  );
};

export default GyramaisInput;
