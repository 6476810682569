// Igual ao core/src/components/modals/EditUserModal/Tabs/inputOptions.js

const educationOptions = [
  'Analfabeto',
  'Até 4ª série (5º. ano) incompleta do Ensino Fundamental',
  '4ª série (5º. ano) completa do Ensino Fundamental (antigo 1º grau ou primário)',
  'Da 5ª à 8ª série (6º. a 9º. ano) do Ensino Fundamental (antigo 1º grau ou ginásio)',
  'Ensino Fundamental completo (antigo 1º grau ou primário e ginasial)',
  'Ensino Médio incompleto (antigo 2º grau, secundário ou colegial)',
  'Ensino Médio completo (antigo 2º grau, secundário ou colegial)',
  'Educação Superior incompleta',
  'Educação Superior completa',
];

const badesulRole = [
  'Tomador',
  'Fiador/Avalista',
  'Interveniente Hipotecante',
  'Sócio',
  'Representante Legal',
];

const race = [
  'NÃO SE APLICA',
  'INDÍGENA',
  'BRANCA',
  'NEGRA',
  'AMARELA',
  'PARDA',
  'NÃO DECLARADO',
];

const occupation = [
  'ABALIZADOR (AREAS AGRICOLAS)',
  'ABASTECEDOR SEM ESPECIFICACAO',
  'ABATEDOR (DE ANIMAIS)',
  'ABELHEIRO',
  'ABETUMADOR',
  'ACABADOR SEM ESPECIFICACAO',
  'ACOMPANHADOR DE COMPRAS',
  'ACOMPANHANTE',
  'ACONDICIONADOR',
  'ACOUGUEIRO',
  'ACROBATA',
  'ACUPUNTURISTA',
  'ADAPTADOR DE PECAS TEATRAIS',
  'ADERECISTA',
  'ADMINISTRADOR SEM ESPECIFICACAO',
  'ADVOGADO',
  'AEROMOCO',
  'AERONAUTA SEM ESPECIFICACAO',
  'AEROVIARIO SEM ESPECIFICAO',
  'AFIADOR EM GERAL',
  'AFINADOR SEM ESPECIFICACAO',
  'AGENCIADOR SEM ESPECIFICACAO',
  'AGENTE ADMINISTRATIVO (SERVICO PUBLICO)',
  'AGENTE COMERCIAL',
  'AGRICULTOR',
  'AGRIMENSOR (CURSO SUPERIOR)',
  'AGRIMENSOR (SEM CURSO SUPERIOR)',
  'AGRONOMO (SEM CURSO SUPERIOR)',
  'AGRONOMO',
  'AGROPECUARISTA',
  'AGROTECNICO',
  'AJUDANTE GERAL',
  'AJULEJISTA',
  'ALCEADOR',
  'ALFAIATE',
  'ALFANDEGARIO',
  'ALIMENTADOR DE LINHA',
  'ALIMENTADOR DE LINHA DE PRODUCAO',
  'ALIMENTADOR DE PRODUCAO',
  'ALIMENTADOR DE ROTATIVA',
  'ALMOXARIFE',
  'AMBULANTE',
  'AMOLADOR EM GERAL',
  'ANALISTA CONTABIL',
  'ANALISTA DE COMPUTADOR',
  'ANALISTA DE CONTABILIDADE',
  'ANALISTA DE CONTROLE DE QUALIDADE',
  'ANALISTA DE CREDITO E COBRANCA',
  'ANALISTA DE DADOS',
  'ANALISTA DE ESTOQUES',
  'ANALISTA DE PROCESSOS',
  'ANALISTA DE QUALIDADE',
  'ANALISTA DE SISTEMAS',
  'ANALISTA ECONOMICO-FINANCEIRO',
  'ANALISTA FINANCEIRO E CONTABIL',
  'ANALISTA SEM ESPECIFICACAO',
  'ANATOMISTA',
  'ANODIZADOR',
  'ANOTADOR SEM ESPECIFICACAO',
  'ANTIQUARIO',
  'ANTROPOLOGO',
  'APARELHADOR EM GERAL',
  'APLICADOR SEM ESPECIFICACAO',
  'APLICADOR SINTETICO',
  'APONTADOR SEM ESPECIFICACAO',
  'APOSENTADO',
  'APRENDIZ SEM ESPECIFICACAO',
  'APRESENTADOR DE ESPETACULOS',
  'ARBITRO DESPORTIVO',
  'ARGAMASSADOR',
  'ARMADOR SEM ESPECIFICACAO',
  'ARMAZENISTA',
  'ARMEIRO',
  'ARPOADOR',
  'ARQUEOLOGO',
  'ARQUITETO',
  'ARQUIVISTA',
  'ARQUIVOLOGISTA',
  'ARRECADADOR',
  'ARRENDATARIO',
  'ARRUMADEIRA',
  'ARRUMADOR (COMERCIO)',
  'ARTE CULINARIA (2o.GRAU)',
  'ARTE CULINARIA (FUNDAMENTAL)',
  'ARTESAO',
  'ARTIFICE SEM ESPECIFICACAO',
  'ARTISTA PLASTICO',
  'ARTISTA SEM ESPECIFICACAO',
  'ASCENSORISTA',
  'ASSESSOR SEM ESPECIFICACAO',
  'ASSISTENTE ADMINISTRATIVO (SERVICO PUBLICO)',
  'ASSISTENTE COMERCIAL',
  'ASSISTENTE CONTABIL (CURSO SUPERIOR)',
  'ASSISTENTE CONTABIL (SEM CURSO SUPERIOR)',
  'ASSISTENTE CONTROLE ECON.(S/CURSO SUPERIOR)',
  'ASSISTENTE DE ARTE SEM ESPECIFICACAO',
  'ASSISTENTE DE COBRANCA',
  'ASSISTENTE DE COMPRAS',
  'ASSISTENTE DE CONTAB. (SEM CURSO SUPERIOR)',
  'ASSISTENTE DE DIRECAO',
  'ASSISTENTE DE ENSINO',
  'ASSISTENTE DE FINANCEIRA',
  'ASSISTENTE DE IMPORTACAO',
  'ASSISTENTE DE MATERIAL',
  'ASSISTENTE DE PLANEJAMENTO',
  'ASSISTENTE DE PROPAGANDA',
  'ASSISTENTE DE TERMAS',
  'ASSISTENTE DE VENDAS',
  'ASSISTENTE DEPTO JURIDICO(S/CURSO SUPERIOR)',
  'ASSISTENTE ESCOLAR',
  'ASSISTENTE FINANCEIRO (2o. GRAU)',
  'ASSISTENTE FINANCEIRO (SEM 2o. GRAU)',
  'ASSISTENTE JURIDICO (CURSO SUPERIOR)',
  'ASSISTENTE JURIDICO (SEM CURSO SUPERIOR)',
  'ASSISTENTE SEM ESPECIFICACAO',
  'ASSISTENTE SOCIAL',
  'ASSISTENTE TECNICO',
  'ASSOALHADOR',
  'ASTROFISICO',
  'ASTROLOGO',
  'ASTRONOMO',
  'ATENDENTE COMERCIAL',
  'ATENDENTE HOSPITALAR',
  'ATENDENTE ODONTOLOGICO',
  'ATENDENTE SEM ESPECIFICACAO',
  'ATENDENTE SOCIAL',
  'ATENDENTE VETERINARIO',
  'ATLETA PROFISSIONAL',
  'ATOR',
  'ATUARIO',
  'AUDIO (FUNDAMENTAL)',
  'AUDITOR',
  'AUTONOMO',
  'AUX. DE TEC. DE PECUARIA (SEM CURSO SUPERIOR)',
  'AUX. TECNICO DE AGRICULTURA(S/CURSO SUPERIOR)',
  'AUX. TECNICO DE AGRIMENSURA(S/CURSO SUPERIOR)',
  'AUXILIAR ADMINISTRATIVO',
  'AUXILIAR DE ABATE',
  'AUXILIAR DE ACABAMENTO',
  'AUXILIAR DE ADVOGADO',
  'AUXILIAR DE AGENCIA SEM ESPECIFICACAO',
  'AUXILIAR DE ALMOXARIFADO',
  'AUXILIAR DE AMBULATORIO',
  'AUXILIAR DE AMNUTENCAO SEM ESPECIFICACAO',
  'AUXILIAR DE ARMAZEM',
  'AUXILIAR DE ARQUITETO (SEM CURSO SUPERIOR)',
  'AUXILIAR DE ARQUIVO',
  'AUXILIAR DE ASSESSORIA',
  'AUXILIAR DE BANCO DE SANGUE',
  'AUXILIAR DE BIBLIOTECA',
  'AUXILIAR DE CAMPO',
  'AUXILIAR DE CARTORIO',
  'AUXILIAR DE COBRANCA',
  'AUXILIAR DE COMPRAS',
  'AUXILIAR DE CONTABILIDADE',
  'AUXILIAR DE CONTAS A PAGAR',
  'AUXILIAR DE CONTAS CORRENTES',
  'AUXILIAR DE CONTROLE DE QUALIDADE',
  'AUXILIAR DE CONTROLE SEM ESPECIFICACAO',
  'AUXILIAR DE COPA',
  'AUXILIAR DE CORTE SEM ESPECIFICACAO',
  'AUXILIAR DE COZINHA',
  'AUXILIAR DE CRECHE',
  'AUXILIAR DE CREDITO E COBRANCA',
  'AUXILIAR DE CUSTOS',
  'AUXILIAR DE CUSTOS E ORCAMENTOS',
  'AUXILIAR DE DENTISTA',
  'AUXILIAR DE DEPARTAMENTO DE ESPORTES',
  'AUXILIAR DE DEPARTAMENTO DE PESSOAL',
  'AUXILIAR DE DEPOSITO',
  'AUXILIAR DE DESPENSA',
  'AUXILIAR DE DOCUMENTACAO',
  'AUXILIAR DE ELETRONICA',
  'AUXILIAR DE EMBALAGEM',
  'AUXILIAR DE EMBARQUE',
  'AUXILIAR DE ENCADERNAGEM',
  'AUXILIAR DE ENCAIXOTADOR',
  'AUXILIAR DE ENFERMAGEM',
  'AUXILIAR DE ENGENHARIA(SEM CURSO SUPERIOR)',
  'AUXILIAR DE ENSINO',
  'AUXILIAR DE ESCRITA',
  'AUXILIAR DE ESCRITA FISCAL',
  'AUXILIAR DE ESCRITORIO (SERVICO PUBLICO)',
  'AUXILIAR DE ESTATISTICA',
  'AUXILIAR DE ESTOQUE',
  'AUXILIAR DE EXECUTIVO',
  'AUXILIAR DE EXPEDICAO E RECEPCAO',
  'AUXILIAR DE FABRICACAO',
  'AUXILIAR DE FARMACIA',
  'AUXILIAR DE FATURAMENTO',
  'AUXILIAR DE FIACAO',
  'AUXILIAR DE FILMAGEM',
  'AUXILIAR DE FISIOTERAPIA',
  'AUXILIAR DE FORNO (SEGUNDA FUSAO)',
  'AUXILIAR DE GARCON',
  'AUXILIAR DE GERENTE',
  'AUXILIAR DE HIPODERMIA',
  'AUXILIAR DE ILUMINACAO',
  'AUXILIAR DE IMPORTACAO/EXPORTACAO',
  'AUXILIAR DE IMPRENSA(SEM CURSO SUPERIOR)',
  'AUXILIAR DE INFORMACAO',
  'AUXILIAR DE INSPECAO',
  'AUXILIAR DE JUSTICA',
  'AUXILIAR DE LABORATORIO',
  'AUXILIAR DE LIMPEZA',
  'AUXILIAR DE MANOBRAS (FERROVIAS)',
  'AUXILIAR DE MANUTENCAO (EDIFICIO)',
  'AUXILIAR DE MANUTENCAO (INDUSTRIA)',
  'AUXILIAR DE MAQUINISTA (FERROVIAS)',
  'AUXILIAR DE MECANICO SEM ESPECIFICACAO',
  'AUXILIAR DE MECANIZACAO',
  'AUXILIAR DE MEDICAO (SEM CURSO SUPERIOR)',
  'AUXILIAR DE MEDICINA',
  'AUXILIAR DE MICROFILMAGEM',
  'AUXILIAR DE MONTAGEM',
  'AUXILIAR DE MOTORISTA DE PESCA',
  'AUXILIAR DE NUTRICAO',
  'AUXILIAR DE ODONTOLOGIA',
  'AUXILIAR DE ONCOLOGIA',
  'AUXILIAR DE ORCAMENTO',
  'AUXILIAR DE PESQUISA (SEM CURSO SUPERIOR)',
  'AUXILIAR DE PESQUISA(CURSO SUP.OU SEG.GRAU)',
  'AUXILIAR DE PESSOAL',
  'AUXILIAR DE PLANEJAMENTO',
  'AUXILIAR DE PLASTIFICADOR',
  'AUXILIAR DE PORTARIA',
  'AUXILIAR DE PRODUCAO',
  'AUXILIAR DE PROTESE',
  'AUXILIAR DE RECEBIMENTO',
  'AUXILIAR DE REFRIGERACAO',
  'AUXILIAR DE REGISTRO (INDUSTRIA)',
  'AUXILIAR DE RELACOES HUMANAS',
  'AUXILIAR DE SEGURANCA',
  'AUXILIAR DE SEGUROS',
  'AUXILIAR DE SELECAO',
  'AUXILIAR DE SERVICO JURIDICO',
  'AUXILIAR DE SERVICO MEDICO',
  'AUXILIAR DE SERVICO POSTAL',
  'AUXILIAR DE SERVICO SOCIAL',
  'AUXILIAR DE SONDAGEM (EXCETO DE PETROLEO)',
  'AUXILIAR DE SUPERVISAO SEM ESPECIFICACAO',
  'AUXILIAR DE SUPRIMENTOS',
  'AUXILIAR DE TERAPIA OCUPACIONAL',
  'AUXILIAR DE TESOURARIA',
  'AUXILIAR DE TIROTEX',
  'AUXILIAR DE TOPOGRAFO (SEM CURSO SUPERIOR)',
  'AUXILIAR DE TRAFEGO',
  'AUXILIAR DE USINAGEM',
  'AUXILIAR DE VENDAS',
  'AUXILIAR DE VETERINARIA',
  'AUXILIAR FINANCEIRO (SEM CURSO SUPERIOR)',
  'AUXILIAR FISCAL',
  'AUXILIAR GERAL (FUNDAMENTAL OU MENOS)',
  'AUXILIAR GRAFICO',
  'AUXILIAR HOSPITALAR',
  'AUXILIAR INDUSTRIAL',
  'AUXILIAR QUIMICO',
  'AUXILIAR SEM ESPECIFICACAO (2o.GRAU)',
  'AUXILIAR SEM ESPECIFICACAO (SEM 2o.GRAU)',
  'AUXILIAR SOCIAL',
  'AUXILIAR TECNICO DE SINISTROS',
  'AUXILIAR TECNICO SEM ESPECIFICACAO',
  'AVALIADOR EM GERAL',
  'AVICULTOR',
  'AZULEJADOR',
  'BABA',
  'BACHAREL EM LETRAS',
  'BACHAREL EM TURISMO',
  'BACTERIOLOGISTA',
  'BAGACEIRO DE SISAL',
  'BAGAGEIRO',
  'BAILARINO',
  'BALANCEADOR (AFERICAO DE BALANCAS)',
  'BALANCEIRO',
  'BALANCISTA (AFERICAO DE BALANCAS)',
  'BALATEIRO',
  'BALCONISTA SEM ESPECIFICACAO',
  'BAMBURISTA',
  'BANCARIO SEM ESPECIFICACAO',
  'BANDEIRINHA',
  'BARBEIRO',
  'BARCACEIRO DE CACAU',
  'BARMAN',
  'BARQUEIRO',
  'BARRAQUEIRO (COMERCIO)',
  'BATERISTA (MUSICO)',
  'BATERISTA DE ACETILENO',
  'BEDEL',
  'BENEFICIADOR',
  'BENEFICIARIO',
  'BERCARISTA',
  'BETONEIRISTA',
  'BETONEIRO',
  'BETUMADOR',
  'BIBLIOGRAFO',
  'BIBLIOTECARIO',
  'BILHETEIRO EM GERAL',
  'BINADOR',
  'BIOFISICO',
  'BIOLOGISTA',
  'BIOLOGO',
  'BIOMEDICO',
  'BIOQUIMICO',
  'BISCATEIRO SEM ESPECIFICACAO',
  'BISELADOR DE CRISTAIS',
  'BLASTER',
  'BLOCADOR',
  'BLOQUISTA',
  'BOBINADOR SEM ESPECIFICACAO',
  'BOBINEIRO (PAPEL)',
  'BOBVINADOR DE PAPEL',
  'BOIADEIRO',
  'BOLADOR',
  'BOLEIRO (COURO)',
  'BOLSEIRO',
  'BOMBEADOR (REFINO DE PETROLEO)',
  'BOMBEIRO (FORA EXCECAO)',
  'BOMBEIRO HIDRAULICO',
  'BOMBEIRO SEM ESPECIFICACAO',
  'BOMBISTA',
  'BOMBONEIRO',
  'BORDADEIRA',
  'BORDADOR',
  'BORRACHEIRO',
  'BOTANICO',
  'BOXEADOR',
  'BOY',
  'BREQUISTA',
  'BRITADOR SEM ESPECIFICACAO',
  'BROCHADOR',
  'BROCHURISTA',
  'BRONZEADOR DE METAIS',
  'BRONZEADOR SEM ESPECIFICACAO',
  'BROQUEADOR DE CILINDROS',
  'BRUNIDOR DE CILINDROS',
  'BRUNIDOR DE METAIS',
  'BSLSNCEADOR DE VEICULOS',
  'BUCHEIRO',
  'BUTEIRO',
  'CABECEIRO',
  'CABELEIREIRO',
  'CABINEIRO (FERROVIAS)',
  'CABINEIRO (SERVICOS)',
  'CABISTA',
  'CABO DE CAMPO (AGRICULTURA)',
  'CABO DE FOGO',
  'CABO DE TURMA (AGRICULTURA)',
  'CACADOR',
  'CACAMBEIRO (METAIS)',
  'CADASTRADOR DE PESSOAL',
  'CADASTRADOR SEM ESPECIFICACAO',
  'CADASTRISTA DE PESSOAL',
  'CAFETEIRO',
  'CAIADOR',
  'CAIXA',
  'CAIXEIRO',
  'CAIXEIRO-VIAJANTE',
  'CAIXOTEIRO',
  'CALAFETADOR',
  'CALANDRISTA SEM ESPECIFICACAO',
  'CALCADOR SEM ESPECIFICACAO',
  'CALCEIRO',
  'CALCETEIRO',
  'CALCULISTA DE CUSTOS',
  'CALCULISTA EM GERAL',
  'CALDEIREIRO',
  'CALDEIREIRO-MONTADOR',
  'CALDEIRISTA',
  'CALIBRADOR (DE MEDIDORES)',
  'CALISTA',
  'CAMARADA (PECUARIA)',
  'CAMAREIRO',
  'CAMARISTA',
  'CAMBISTA (FUNDAMENTAL)',
  'CAMBISTA (SEGUNDO GRAU)',
  'CAMBITEIRO DE CANA-DE-ACUCAR',
  'CAMBUZEIRO',
  'CAMELO',
  'CAMERAMAN',
  'CAMISEIRO',
  'CAMPEIRO',
  'CANALEIRO',
  'CANAVIEIRO',
  'CANCHEADOR (ERVA-MATE)',
  'CANELEIRO',
  'CANTEIRO EM GERAL',
  'CANTINEIRO',
  'CANTONEIRO',
  'CANTOR',
  'CAPATAZ EM GERAL',
  'CAPELAO',
  'CAPITAO DE MINAS',
  'CAPITAO DE PESCA',
  'CAPOTEIRO',
  'CARAMELISTA',
  'CARAPINA',
  'CARCEREIRO',
  'CARDADOR',
  'CARDEXISTA',
  'CARDISTA',
  'CARGUEIRO',
  'CARICATURISTA',
  'CARIMBADOR',
  'CARPIDOR SEM ESPECIFICACAO',
  'CARPINTEIRO SEM ESPECIFICACAO',
  'CARRANQUEIRO',
  'CARREGADOR (FORA EXCECOES)',
  'CARREGADOR SEM ESPECIFICACAO',
  'CARRETEIRO',
  'CARRETEIRO (FIOS)',
  'CARROCEIRO',
  'CARTAZISTA',
  'CARTEIRO',
  'CARTONADOR',
  'CARTONAGEIRO',
  'CARTONISTA',
  'CARTORARIO',
  'CARVOEIRO',
  'CARVOEJADOR',
  'CASEADOR',
  'CASEIRO',
  'CASQUEIRO (MADEIRA)',
  'CASTRADOR',
  'CAVOCADOR',
  'CAVOUQUEIRO',
  'CEIFADOR DE ARROZ',
  'CEMENTADOR DE METAIS',
  'CENOGRAFO',
  'CENOTECNICO',
  'CERAMISTA SEM ESPECIFICACAO',
  'CERVEJEIRO',
  'CERZIDOR',
  'CESTEIRO',
  'CEVADOR (PECUARIA)',
  'CHACAREIRO',
  'CHAMUSCADOR DE LA',
  'CHANFRADOR (MADEIRA)',
  'CHAPEADOR SEM ESPECIFICACAO',
  'CHAPEIRO',
  'CHAPELEIRO SEM ESPECIFICACAO',
  'CHAPISTA (TIPOGRAFICO)',
  'CHAPISTA DE VEICULOS',
  'CHARGISTA',
  'CHARQUEADOR',
  'CHARRETEIRO',
  'CHARUTEIRO',
  'CHAVEIRO',
  'CHEFE DE AGENCIA DE CORREIO',
  'CHEFE DE ALMOXARIFADO',
  'CHEFE DE COBRANCA',
  'CHEFE DE COMPRAS',
  'CHEFE DE CONTROLE DE PATRIMONIO',
  'CHEFE DE DESPENSA',
  'CHEFE DE DIQUE OU VARADOURO',
  'CHEFE DE ESCRITORIO NAO ESPECIFICADO',
  'CHEFE DE ESTABULO',
  'CHEFE DE ESTACAO (FERROVIAS)',
  'CHEFE DE EXPEDICAO',
  'CHEFE DE GARAGEM',
  'CHEFE DE MANUTENCAO',
  'CHEFE DE MONTAGEM',
  'CHEFE DE MOVIMENTO (FERROVIAS)',
  'CHEFE DE OPERACOES DE VOO',
  'CHEFE DE PLANEJAMENTO (SEGUNDO GRAU)',
  'CHEFE DE SECAO NAO ESPECIFICADO',
  'CHEFE DE SERVICOS DE TELECOMUNICACOES',
  'CHEFE DE SERVICOS DE TRANSP.P/VIAS NAVEGAVEIS',
  'CHEFE DE SERVICOS DE TRANSPORTE AEREO',
  'CHEFE DE SERVICOS DE TRANSPORTES RODOVIARIOS',
  'CHEFE DE TRAFEGO (TRANSPORTES COLETIVOS)',
  'CHEFE DE TREM',
  'CHEFE DE TURMA (AGRICULTURA)',
  'CHEFE DE VENDAS',
  'CHEFE SEM ESPECIFICACAO',
  'CHULEADOR',
  'CHUMBADOR',
  'CHURRAQUEIRO',
  'CICERONE',
  'CICLISTA',
  'CIENTISTA POLITICO',
  'CIGARREIRO',
  'CILINDRADOR DE COURO',
  'CILINDREIRO (PASTA PARA PAPEL)',
  'CILINDREIRO DIGESTORISTA',
  'CILINDRISTA (BORRACHA)',
  'CILINDRISTA (IMPRENSA)',
  'CILINDRISTA SEM ESPECIFICACAO',
  'CIMENTADOR (POCOS DE PETROLEO E GAS)',
  'CINEGRAFISTA',
  'CINTEIRO',
  'CINZELADOR SEM ESPECIFICACAO',
  'CIRCENSE',
  'CIRURGIAO DENTISTA',
  'CIRURGIAO EM GERAL',
  'CITRICULTOR',
  'CLASSIFICADOR SEM ESPECIFICACAO',
  'CLICHERISTA',
  'CLINICO GERAL',
  'COBRADOR (ALUGUEIS)',
  'COBRADOR SEM ESPECIFICACAO',
  'COCHEIRO',
  'CODIFICADOR',
  'COLADOR SEM ESPECIFICACAO',
  'COLCHOEIRO',
  'COLETOR SEM ESPECIFICACAO (CURSO SUPERIOR)',
  'COLETOR SEM ESPECIFICACAO(SEM CURSO SUPERIOR)',
  'COLHEDOR (VIDRARIA)',
  'COLHEDOR DE ALGODAO',
  'COLHEDOR DE AMOSTRAS (MINAS)',
  'COLHEDOR DE ARROZ',
  'COLHEDOR DE BANANAS',
  'COLHEDOR DE CACAU',
  'COLHEDOR DE CAFE',
  'COLHEDOR DE CANA-DE-ACUCAR',
  'COLHEDOR DE COCO',
  'COLHEDOR DE FUMO',
  'COLHEDOR DE UVA',
  'COLOCADOR DE CAPAS(IMPRESSOS)',
  'COLOCADOR DE CARPETE',
  'COLOCADOR DE FERRAMENTAS',
  'COLOCADOR DE LIÇOS',
  'COLOCADOR DE MARMORE',
  'COLOCADOR DE PALMILHAS(CALCADOS)',
  'COLOCADOR DE TELHAS',
  'COLORISTA',
  'COLORISTA DE TECIDOS(2 GRAU)',
  'COMANDANTE(AVIACAO)',
  'COMANDANTE(NAVEGACAO INTERIOR)',
  'COMANDANTE(NAVEGACAO MARITIMA)',
  'COMANDANTE(PESCA E ALTO-MAR)',
  'COMBINADOR DE PELES',
  'COMEDIANTE',
  'COMENTARISTA(RADIO E TV)',
  'COMERCIANTE',
  'COMERCIARIO SEM ESPECIFICACAO',
  'COMISSARIO DE AGENCIA',
  'COMISSARIO DE BORDO(AERONAVES)',
  'COMISSARIO DE BORDO(EMBARCACOES)',
  'COMISSARIO DE CARROS-LEITO(FERROVIAS)',
  'COMISSARIO DE ONIBUS',
  'COMISSARIO SEM ESPECIFICACAO',
  'COMMIS',
  'COMPAGINADOR DE FOTOCOMPOSICAO',
  'COMPENSADOR DE BANCO',
  'COMPLETADOR(INDUSTRIA)',
  'COMPOSITOR DE TRENS',
  'COMPOSITOR MUSICAL',
  'COMPOSITOR(FORA EXCECOES)',
  'COMPOTEIRO(REFINO DE ACUCAR)',
  'COMPRADOR',
  'COMPRESSORISTA',
  'COMPUTOMETRISTA',
  'COMUNICADOR VISUAL(2 GRAU OU SUPERIOR)',
  'COMUNICOLOGO',
  'CONCILIADOR',
  'CONCRETISTA EM GERAL',
  'CONDUTOR DE BONDE',
  'CONDUTOR DE PAPEL',
  'CONFECCIONADOR SEM ESPECIFICACAO',
  'CONFEITEIRO',
  'CONFERENTE SEM ESPECIFICACAO',
  'CONFERIDOR(CARTOES E FITAS)',
  'CONICALEIRO',
  'CONSELHEIRO(DIPLOMACIA)',
  'CONSELHEIRO(ORIENTACAO PROFISSIONAL)',
  'CONSERVADOR DE ESTRADAS',
  'CONSERVADOR DE LINHA',
  'CONSERVADOR DE MUSEU',
  'CONSERVADOR DE PESCADO',
  'CONSERVEIRO',
  'CONSTRUTOR CIVIL',
  'CONSTRUTOR SEM ESPECIFICACAO',
  'CONSULTOR JURIDICO',
  'CONSULTOR PROFISSIONAL',
  'CONSULTOR SEM ESPECIFICACAO',
  'CONSULTOR TECNICO',
  'CONTABILISTA(2 GRAU)',
  'CONTABILISTA(CURSO SUPERIOR)',
  'CONTABILISTA(SEM 2 GRAU)',
  'CONTACTOLOGO',
  'CONTADOR DE PRODUCAO',
  'CONTADOR(2 GRAU)',
  'CONTADOR(CURSO SUPERIOR)',
  'CONTATO(PUBLICIDADE)',
  'CONTINUISTA',
  'CONTINUO',
  'CONTRA REGRA',
  'CONTRA-MESTRE SEM ESPECIFICACAO',
  'CONTROLADOR SEM ESPECIFICACAO',
  'CONTROLISTA SEM ESPECIFICACAO',
  'CONZELADOR DE METAIS PRECIOSOS',
  'COORDENADOR DE ENSINO',
  'COORDENADOR DE PRODUCAO',
  'COORDENADOR DE SISTEMAS',
  'COORDENADOR PEDAGOGICO',
  'COORDENADOR SEM ESPECIFICACAO',
  'COPEIRO',
  'COPIDESQUE',
  'COPISTA',
  'COPISTA (CARTOES JACQUARD)',
  'COQUELEIRO',
  'COREOGRAFO',
  'CORREEIRO',
  'CORRESPONDENTE (JORNALISTA)',
  'CORRESPONDENTE COMERCIAL',
  'CORRETOR SEM ESPECIFICACAO',
  'CORTADOR SEM ESPECIFICACAO',
  'COSMETOLOGO (CURSO SUPERIOR)',
  'COSMETOLOGO (SEM CURSO SUPERIOR)',
  'COSTUREIRO SEM ESPECIFICACAO',
  'COVEIRO',
  'COZINHEIRO',
  'CRAVADOR SEM ESPECIFICACAO',
  'CRIADO DE HOTEL',
  'CRIADOR DE ABELHAS',
  'CRIADOR DE AVES',
  'CRIADOR DE AVES PARA POSTURA',
  'CRIADOR DE BICHOS-DA-SEDA',
  'CRIADOR DE BUFALOS',
  'CRIADOR DE CAPRINOS',
  'CRIADOR DE CAVALOS',
  'CRIADOR DE COELHOS',
  'CRIADOR DE GADO',
  'CRIADOR DE GATOS',
  'CRIADOR DE JABUTIS',
  'CRIADOR DE MODELOS DE VESTUARIO',
  'CRIADOR DE OVELHAS',
  'CRIADOR DE PEIXES',
  'CRIADOR DE PORCOS',
  'CRIADOR DE RAS',
  'CRIADOR DE TARTARUGAS',
  'CRIADOR DE ZEBU',
  'CRIADOR SEM ESPECIFICACAO',
  'CRITICO EM GERAL',
  'CRITICO EM GERAL',
  'CROMADOR',
  'CRONISTA DESPORTIVO',
  'CRONISTA LITERARIO',
  'CRONOANALISTA',
  'CRONOMETRISTA (FORA EXCECAO)',
  'CRONOMETRISTA DESPORTIVO',
  'CRONOMETRISTA SEM ESPECIFICACAO',
  'CUBADOR DE MADEIRA',
  'CULIDOR',
  'CUNHADOR',
  'CUNICULTOR',
  'CURRALEIRO DE PESCA',
  'CURTIDOR',
  'CURTUMEIRO',
  'CURVADOR DE CHAPAS',
  'CURVADOR DE MADEIRA',
  'CURVADOR DE TUBOS DE VIDRO',
  'DANCARINO',
  'DATILOGRAFO',
  'DATILOSCOPISTA',
  'DEBITADOR',
  'DEBULHADOR DE FUMO',
  'DECAPADOR',
  'DECORADOR SEM ESPECIFICACAO',
  'DEDETIZADOR',
  'DEFUMADOR DE CARNE E PESCADO',
  'DEFUMADOR DE LATEX',
  'DEGUSTADOR DE CACAU',
  'DEGUSTADOR DE CAFE',
  'DEGUSTADOR DE CHA',
  'DEGUSTADOR DE VINHOS E LICORES',
  'DELEGADO DE POLICIA',
  'DELINEADOR',
  'DEMOLIDOR DE EDIFICIOS',
  'DEMONSTRADOR',
  'DENTISTA',
  'DEPILADOR DE PELES',
  'DEPUTADO',
  'DESBASTADOR DE PLASTICOS',
  'DESBASTADOR SEM ESPECIFICACAO',
  'DESCARNADOR (FORA EXCECAO)',
  'DESCARNADOR DE COUROS E PELES',
  'DESCARNADOR SEM ESPECIFICACAO',
  'DESCAROCADOR DE AZEITONAS',
  'DESCARREGADOR DE FIANDEIRA',
  'DESCARREGADOR DE FORNO (CERAMICA)',
  'DESCASCADOR DE LENHA',
  'DESEMPENADOR (CALDEIRARIA)',
  'DESEMPREGADO',
  'DESENFORNADOR (COQUERIA)',
  'DESENGOMADOR DE FIOS',
  'DESENGOMADOR DE TECIDOS',
  'DESENHISTA ARQUITETONICO',
  'DESENHISTA ARTISTICO',
  'DESENHISTA COPISTA',
  'DESENHISTA DE ARQUITETURA',
  'DESENHISTA DE ARTE-FINAL',
  'DESENHISTA DE DECORACAO',
  'DESENHISTA DE EMBALAGENS',
  'DESENHISTA DE ESTRUTURAS METALICAS',
  'DESENHISTA DE FILATELIA',
  'DESENHISTA DE HERALDICA',
  'DESENHISTA DE ILUSTRACOES',
  'DESENHISTA DE VESTUARIO',
  'DESENHISTA DETALHISTA',
  'DESENHISTA HUMORISTICO',
  'DESENHISTA INDUSTRIAL (CURSO SUPERIOR)',
  'DESENHISTA MECANICO',
  'DESENHISTA PROJETISTA',
  'DESENHISTA PUBLICITARIO',
  'DESENHISTA SEM ESPECIFICACAO',
  'DESENHISTA TECNICO',
  'DESFIBRADOR (LINHO OU JUTA)',
  'DESIDRATADOR DE ALIMENTOS',
  'DESIGNER (2o. GRAU)',
  'DESINCRUSTADOR (POCOS DE PETROLEO E GAS)',
  'DESMONTADOR SEM ESPECIFICACAO',
  'DESOCUPADO',
  'DESOSSADOR',
  'DESPACHADOR DE CARGA (ELETRICA)',
  'DESPACHADOR DE MOVIMENTO (FERROVIAS)',
  'DESPACHANTE ADUANEIRO',
  'DESPACHANTE COMERCIAL',
  'DESPACHANTE SEM ESPECIFICACAO',
  'DESPENSEIRO',
  'DESPONTADOR SEM ESPECIFICACAO',
  'DESSECADOR DE MALTE',
  'DESSULFURADOR (REFINO DE PETROLEO)',
  'DESTILADOR DE PRODUTOS QUIMICO',
  'DESTOCADOR',
  'DESTROCADOR DE PEDRAS',
  'DETETIVE',
  'DETONADOR',
  'DIAGRAMADOR',
  'DIARISTA',
  'DIETISTA',
  'DIGITADOR',
  'DIR.DE EMP.DE PROD.E DISTRIBUICAO DE ENERGIA',
  'DIR.DE EMP.DE PRODUCAO E DISTRIBUICAO DE GAS',
  'DIRETOR ADMINISTRATIVO',
  'DIRETOR AGRICOLA',
  'DIRETOR DE ARTE',
  'DIRETOR DE BANCO',
  'DIRETOR DE CINEMA',
  'DIRETOR DE COMPANHIA DE SEGUROS',
  'DIRETOR DE EMPRESA AGROPECUARIA',
  'DIRETOR DE EMPRESA COMERCIAL',
  'DIRETOR DE EMPRESA DE AGUA E ESGOTO',
  'DIRETOR DE EMPRESA DE COMUNICACOES',
  'DIRETOR DE EMPRESA DE CONSTRUCAO CIVIL',
  'DIRETOR DE EMPRESA DE CONSTRUCAO NAVAL',
  'DIRETOR DE EMPRESA DE EXTRACAO DE PETROLEO',
  'DIRETOR DE EMPRESA DE MINERACAO',
  'DIRETOR DE EMPRESA DE PRESTACAO DE SERVICO',
  'DIRETOR DE EMPRESA DE SERVICOS CULTURAIS',
  'DIRETOR DE EMPRESA DE SERVICOS MEDICOS',
  'DIRETOR DE EMPRESA DE SERVICOS SOCIAIS',
  'DIRETOR DE EMPRESA DE TRANSPORTE',
  'DIRETOR DE EMPRESA FINANCEIRA',
  'DIRETOR DE EMPRESA FLORESTAL',
  'DIRETOR DE EMPRESA HOTELEIRA',
  'DIRETOR DE EMPRESA IMOBILIARIA',
  'DIRETOR DE EMPRESA MANUFATUREIRA',
  'DIRETOR DE EMPRESA PESQUEIRA',
  'DIRETOR DE EMPRESA SEM ESPECIFICACAO',
  'DIRETOR DE EMPRESAS OUTRAS',
  'DIRETOR DE ESCOLA (EXCETO SUPERIOR)',
  'DIRETOR DE ESCOLA SUPERIOR',
  'DIRETOR DE ESPETACULOS',
  'DIRETOR DE FINANCAS',
  'DIRETOR DE FOTOGRAFIA (CINEMA)',
  'DIRETOR DE MARKETING',
  'DIRETOR DE PATRIMONIO',
  'DIRETOR DE PESSOAL',
  'DIRETOR DE PRODUCAO (RADIO E TV)',
  'DIRETOR DE PUBLICIDADE',
  'DIRETOR DE RECURSOS HUMANOS',
  'DIRETOR DE REDACAO',
  'DIRETOR DE RELACOES DE TRABALHO',
  'DIRETOR DE RELACOES INDUSTRIAIS',
  'DIRETOR DE RELACOES PUBLICAS',
  'DIRETOR DE TELEVISAO',
  'DIRETOR DE TREINAMENTO',
  'DIRETOR DE VENDAS',
  'DIRETOR FINANCEIRO',
  'DIRETOR PEDAGOGICO',
  'DIRETOR SEM ESPECIFICACAO',
  'DIRETOR TEATRAL',
  'DIRETOR TECNICO',
  'DISC-JOCKEY',
  'DISCOTECARIO (EXCETO PROGRAMADOR)',
  'DISCOTECARIO PROGRAMADOR',
  'DISTRIBUIDOR DE CARGAS (ENERGIA ELETRICA)',
  'DISTRIBUIDOR DE ESPULAS',
  'DISTRIBUIDOR DE FERRAMENTAS',
  'DISTRIBUIDOR DE FUMO',
  'DISTRIBUIDOR DE MACAROCAS',
  'DISTRIBUIDOR DE MATERIAL',
  'DISTRIBUIDOR DE VEICULOS (FERROVIAS)',
  'DISTRIBUIDOR SEM ESPECIFICACAO',
  'DIVISOR DE COUROS',
  'DIVULGADOR',
  'DOBRADOR DE CALCADOS',
  'DOBRADOR DE ROUPAS',
  'DOBRADOR SEM ESPECIFICACAO',
  'DOCEIRO',
  'DOCUMENTALISTA',
  'DOMADOR (CIRCO)',
  'DOMADOR (PECUARIA)',
  'DOMESTICA (EMPREGADA)',
  'DONA-DE-CASA',
  'DRAGEADOR (MEDICAMENTOS)',
  'DRAGISTA',
  'DUCHISTA (SAUNAS E AFINS)',
  'ECOLOGISTA',
  'ECONOMIARIO',
  'ECONOMISTA (CURSO SUPERIOR)',
  'ECONOMISTA (SEM CURSO SUPERIOR)',
  'ECONOMISTA DOMESTICO',
  'ECONOMO (HOTELARIA)',
  'ECOSSONDADOR',
  'EDITOR (DE LIVROS)',
  'EDUCADOR DE SAUDE PUBLICA',
  'EDUCADOR SEM ESPECIFICACAO',
  'ELETRICISTA SEM ESPECIFICACAO',
  'ELETRICITARIO',
  'ELETROMECANICO',
  'ELETRONICO ANALISTA',
  'ELETROPISTA',
  'ELETROTECNICO',
  'EMBALADOR SEM ESPECIFICACAO',
  'EMBALSAMADOR',
  'EMBARCADOR',
  'EMBLOCADOR',
  'EMBORRACHADOR',
  'EMBUTIDOR (MADEIRA)',
  'EMENDADOR DE CABOS ELETRICOS E TELEFONICOS',
  'EMENDADOR DE CORDAS E CABOS',
  'EMENDADOR SEM ESPECIFICACAO',
  'EMPACOTADOR SEM ESPECIFICACAO',
  'EMPILHADEIRISTA',
  'EMPILHADOR',
  'EMPREGADA DOMESTICA',
  'EMPREGADO DE LIMPEZA',
  'EMPREIREIRO DE OBRAS',
  'EMPRESARIO ARTISTICO',
  'EMPRESARIO DE ESPETACULOS',
  'EMPRESARIO DE SERVICOS FUNERARIOS',
  'EMPRESARIO SEM ESPECIFICACAO',
  'ENCADERNADOR',
  'ENCAIXOTADOR',
  'ENCALHADOR SEM ESPECIFICACAO',
  'ENCANADOR',
  'ENCARREGADO ADMINISTRATIVO',
  'ENCARREGADO DE ALOJAMENTO',
  'ENCARREGADO DE ARMAZEM',
  'ENCARREGADO DE CABINE (FERROVIAS)',
  'ENCARREGADO DE CARNES',
  'ENCARREGADO DE CASA DE MAQUINA',
  'ENCARREGADO DE COMESTIVEIS (COMERCIO)',
  'ENCARREGADO DE COMPRAS',
  'ENCARREGADO DE CREDITO',
  'ENCARREGADO DE CUSTOS',
  'ENCARREGADO DE DEPARTAMENTO (ESCRITORIO)',
  'ENCARREGADO DE DEPARTAMENTO (FABRICA)',
  'ENCARREGADO DE DEPARTAMENTO NAO ESPECIFICADO',
  'ENCARREGADO DE EMBALAGEM',
  'ENCARREGADO DE EMPACOTAMENTO',
  'ENCARREGADO DE EXPEDICAO',
  'ENCARREGADO DE EXPORTACAO',
  'ENCARREGADO DE FISCALIZACAO',
  'ENCARREGADO DE FROTA',
  'ENCARREGADO DE IMPORTACAO',
  'ENCARREGADO DE LABORATORIO (CURSO SUPERIOR)',
  'ENCARREGADO DE LABORATORIO (S/CURSO SUPERIOR)',
  'ENCARREGADO DE MANUTENCAO (EDIFICIO)',
  'ENCARREGADO DE MANUTENCAO (INDUSTRIA)',
  'ENCARREGADO DE MANUTENCAO INDUSTRIAL',
  'ENCARREGADO DE MANUTENCAO SEM ESPECIFICAÇAO',
  'ENCARREGADO DE MECANICA (SEÇAO)',
  'ENCARREGADO DE MONTAGEM',
  'ENCARREGADO DE OBRAS',
  'ENCARREGADO DE PRODUCAO',
  'ENCARREGADO DE RESTAURANTE',
  'ENCARREGADO DE SEÇAO (ESCRITORIO)',
  'ENCARREGADO DE SEÇAO (FABRICA)',
  'ENCARREGADO DE SEÇAO NAO ESPECIFICADA',
  'ENCARREGADO DE TEXTURIZAÇAO',
  'ENCARREGADO DE TRAFEGO',
  'ENCARREGADO DE TRANSPORTES',
  'ENCARREGADO DE TURMA (EXCETO AGRICOLA)',
  'ENCARREGADO DE VENDAS',
  'ENCARREGADO DE VIDRARIA',
  'ENCARREGADO GERAL SEM ESPECIFICACAO',
  'ENCARREGADO SEM ESPECIFICAÇAO',
  'ENCERADOR (pisos)',
  'ENCORDOADOR',
  'ENCUBADORA',
  'ENCUNHADOR DE PEDREIRA',
  'ENFARDADOR',
  'ENFERMEIRO (CURSO SUPERIOR)',
  'ENFERMEIRO PRATICO',
  'ENFESTADOR',
  'ENGARRAFADOR',
  'ENGASTADOR',
  'ENGATADOR',
  'ENGENHEIRO AERONAUTICO',
  'ENGENHEIRO AGRIMENSOR',
  'ENGENHEIRO AGRONOMO',
  'ENGENHEIRO ARQUITETO',
  'ENGENHEIRO CARTOGRAFO',
  'ENGENHEIRO CIVIL',
  'ENGENHEIRO DE ALIMENTOS',
  'ENGENHEIRO DE APROVISIONAMENTO',
  'ENGENHEIRO DE CERAMICA E VIDROS',
  'ENGENHEIRO DE CONTROLE DE QUALIDADE',
  'ENGENHEIRO DE CUSTOS',
  'ENGENHEIRO DE EMBALAGEM',
  'ENGENHEIRO DE MANUTENCAO (ELETRICISTA)',
  'ENGENHEIRO DE MANUTENCAO (MECANICA)',
  'ENGENHEIRO DE MATERIAL',
  'ENGENHEIRO DE MINAS',
  'ENGENHEIRO DE NORMAS E ESPECIFICAÇOES',
  'ENGENHEIRO DE OBRAS',
  'ENGENHEIRO DE OPERACAO',
  'ENGENHEIRO DE ORGANIZAÇAO COMERCIAL',
  'ENGENHEIRO DE ORGANIZAÇAO E METODOS',
  'ENGENHEIRO DE ORGANIZAÇAO EM GERAL',
  'ENGENHEIRO DE PATENTES',
  'ENGENHEIRO DE PERFURAÇAO (PETROLEO)',
  'ENGENHEIRO DE PLANEJAMENTO E CONTROLE',
  'ENGENHEIRO DE PROCESSOS',
  'ENGENHEIRO DE PRODUÇAO',
  'ENGENHEIRO DE PROJETOS ELETRICOS',
  'ENGENHEIRO DE QUALIDADE',
  'ENGENHEIRO DE SEGURANÇA DO TRABALHO',
  'ENGENHEIRO DE SEGURANÇA INDUSTRIAL',
  'ENGENHEIRO DE SISTEMAS',
  'ENGENHEIRO DE SOLOS',
  'ENGENHEIRO DE TELECOMUNICAÇOES',
  'ENGENHEIRO DE TEMPOS E MOVIMENTOS',
  'ENGENHEIRO DE TRAFEGO',
  'ENGENHEIRO DE VENDAS',
  'ENGENHEIRO ELETRICISTA',
  'ENGENHEIRO ELETROMECANICO',
  'ENGENHEIRO ELETRONICO',
  'ENGENHEIRO ELETROTECNICO',
  'ENGENHEIRO FLORESTAL',
  'ENGENHEIRO HIDRAULICO',
  'ENGENHEIRO HORTICULTOR',
  'ENGENHEIRO INDUSTRIAL MECANICO',
  'ENGENHEIRO MECANICO',
  'ENGENHEIRO METALURGICO',
  'ENGENHEIRO MINERALOGISTA',
  'ENGENHEIRO NAVAL',
  'ENGENHEIRO NUCLEAR',
  'ENGENHEIRO ORÇCAMENTISTA',
  'ENGENHEIRO QUIMICO (PRODUTOS ALIMENTARES)',
  'ENGENHEIRO QUIMICO(EXC.PRODUTOS ALIMENTARES)',
  'ENGENHEIRO SANITARISTA',
  'ENGENHEIRO SEM ESPECIFICAÇAO',
  'ENGENHEIRO SIDERURGICO',
  'ENGENHEIRO SILVICULTOR',
  'ENGENHEIRO TECNOLOGO DE ALIMENTOS E BEBIDAS',
  'ENGENHEIRO TERMOMECANICO',
  'ENGENHEIRO TEXTIL',
  'ENGOMADOR DE FIOS DE TECIDOS',
  'ENGOMADOR DE ROUPAS',
  'ENGOMADOR DE TECIDOS COM BORRACHA',
  'ENGORDADOR DE GADO',
  'ENGRAXATE',
  'ENGRUPADOR',
  'ENLATADOR',
  'ENOLOGO (CURSO SUPERIOR)',
  'ENQUADRADOR (PEDRA)',
  'ENRAIZADOR DE BONECAS',
  'ENRAMADOR (AGRICOLA)',
  'ENRAMADOR (GRAFICO)',
  'ENROLADOR DE BOBINAS',
  'ENROLADOR DE FIOS',
  'ENROLADOR DE FUMO',
  'ENROLADOR DE GAZE',
  'ENROLADOR DE PANO',
  'ENROLADOR ELETRICO',
  'ENROLADOR SEM ESPECIFICAÇAO',
  'ENSACADOR (FORA EXCEÇOES)',
  'ENSACADOR DE SUPERMERCADO',
  'ENSACADOR SEM ESPECIFICAÇAO',
  'ENTAIPADOR',
  'ENTALHADOR',
  'ENTELADOR',
  'ENTREGADOR DE FERRAMENTAS',
  'ENTREGADOR DE JORNAIS',
  'ENTREGADOR SEM ESPECIFICAÇAO',
  'ENTREVISTADOR DE PESSOAL',
  'ENTREVISTADOR SEM ESPECIFICAÇAO',
  'ENVERNIZADOR',
  'ENXADEIRO SEM ESPECIFICAÇAO',
  'ENXAMEADOR',
  'ENXERTADOR',
  'ENXUGADOR DE COUROS E PELES',
  'ENXUGADOR SEM ESPECIFICACAO',
  'EQUILIBRISTA',
  'ERGOTERAPEUTA',
  'ERVATEIRO',
  'ESBRAVADOR',
  'ESCAFANDRISTA',
  'ESCALADOR DE VOO',
  'ESCARFADOR',
  'ESCARNADOR',
  'ESCAVADEIRISTA',
  'ESCOLHEDOR DE PEDRAS',
  'ESCOLHEDOR DE PEÇAS',
  'ESCOLHEDOR SEM ESPECIFICAÇAO',
  'ESCORADOR DE MINAS',
  'ESCORADOR SEM ESPECIFICAÇAO',
  'ESCOVADOR DE COUROS',
  'ESCREVENTE',
  'ESCRITOR',
  'ESCRITURARIO (FORA EXCEÇOES)',
  'ESCRITURARIO CONTABIL',
  'ESCRITURARIO DE LIVROS FISCAIS',
  'ESCRITURARIO FISCAL',
  'ESCRITURARIO SEM ESPECIFICAÇAO',
  'ESCRIVAO',
  'ESCULPIDOR DE MADEIRA',
  'ESCULTOR',
  'ESGOTEIRO',
  'ESMALTADOR DE CERAMICA',
  'ESMALTADOR DE METAIS NAO PRECIOSOS',
  'ESMALTADOR DE METAIS PRECIOSOS',
  'ESMALTADOR SEM ESPECIFICAÇAO',
  'ESMERILADOR DE METAIS',
  'ESMERILADOR SEM ESPECIFICAÇAO',
  'ESMERILHADOR DE METAIS',
  'ESMERILHADOR SEM ESPECIFICACAO',
  'ESPECIFICADOR DE MATERIAIS',
  'ESPECIFICADOR DE NORMAS TECNICAS',
  'ESPECTOGRAFISTA',
  'ESPECTROMETRISTA',
  'ESPELHADOR',
  'ESPULADEIRA',
  'ESPULADOR',
  'ESQUADRIADOR(MADEIRA)',
  'ESQUILADOR',
  'ESTAFETA SEM ESPECIFICACAO',
  'ESTAFETA(CORREIOS E TELEGRAFOS)',
  'ESTAFETA(EXCETO NOS CORREIOS E TELEGRAFOS)',
  'ESTALEIRADOR',
  'ESTAMPADOR DE COUROS E PELES',
  'ESTAMPADOR DE TECIDOS',
  'ESTAMPADOR SEM ESPECIFICACAO',
  'ESTANHADOR',
  'ESTATISTICO (SEM CURSO SUPERIOR)',
  'ESTATISTICO AUXILIAR',
  'ESTATISTICO(CURSO SUPERIOR)',
  'ESTEIREIRO',
  'ESTENOGRAFO',
  'ESTENOTIPISTA',
  'ESTEREOTIPISTA',
  'ESTERILIZADOR DE ALIMENTOS',
  'ESTERILIZADOR DE PRODUTOS LACTEOS',
  'ESTERQUEIRO',
  'ESTETICISTA',
  'ESTIRADOR DE COUROS E PELES',
  'ESTIRADOR DE FIBRAS',
  'ESTIRADOR DE METAIS',
  'ESTIRADOR DE TUBOS DE METAL SEM COSTURA',
  'ESTIRADOR SEM ESPECIFICACAO',
  'ESTIVADOR',
  'ESTOFADOR EM GERAL',
  'ESTOQUISTA',
  'ESTRIPADOR',
  'ESTROPEIRO',
  'ESTUCADOR (DECORAÇAO E GESSO)',
  'ESTUCADOR (FORA EXCEÇAO)',
  'ESTUCADOR SEM ESPECIFICAÇAO',
  'ESTUDANTE',
  'ESTUFEIRO (TECIDOS)',
  'ESTUFEIRO DE MACHARIA',
  'ESTUFISTA DE MACHARIA',
  'ETIQUETADOR',
  'EVAPOR5ADOR DE OLEO',
  'EVISCERADOR',
  'EXAMINADOR DE CABOS',
  'EXATOR',
  'EXECUTIVO',
  'EXPEDICISTA',
  'EXPEDIDOR DE MATERIAIS',
  'EXPEDIDOR SEM ESPECIFICAÇAO',
  'EXTRUSOR DE BORRACHA',
  'EXTRUSOR DE METAIS',
  'EXTRUSOR DE PLASTICO',
  'EXTRUSOR DE VIDRO',
  'EXTRUSOR SEM ESPECIFICAÇAO',
  'FABRICADOR DE CARIMBOS',
  'FABRICADOR DE VELAS',
  'FAQUEIRO',
  'FARINHEIRO',
  'FARMACEUTICO',
  'FARMACOLOGISTA',
  'FAROLEIRO',
  'FATURISTA',
  'FAXINEIRO',
  'FAXINEIRO (SERVIÇOS DOMESTICOS)',
  'FAZENDEIRO',
  'FECULADOR (CURSO SUPERIOR)',
  'FEIRANTE',
  'FEITOR AGRICOLA',
  'FEITOR DE FERRAMENTARIA',
  'FEITOR DE INSPEÇAO',
  'FEITOR DE MANOBRAS',
  'FEITOR DE OBRAS',
  'FEITOR DE PRODUÇAO',
  'FEITOR DE TURMA (EXCETO AGRICOLA)',
  'FEITOR FLORESTAL',
  'FEITOR SEM ESPECIFICAÇAO',
  'FERMENTADOR',
  'FERRADOR',
  'FERRAMENTEIRO',
  'FERREIRO (FORA EXCEÇAO)',
  'FERREIRO ARMADOR',
  'FERREIRO SEM ESPECIFICAÇAO',
  'FERROVIARIO SEM ESPECIFICAÇAO',
  'FIAMBEIRO',
  'FIANDEIRO (FORA EXCEÇAO)',
  'FIANDEIRO DE FIBRAS ARTIFICIAIS',
  'FIANDEIRO SEM ESPECIFICAÇAO',
  'FICHARISTA',
  'FIEL DE ALMOXARIFADO',
  'FIEL DE VALORES',
  'FIGURINISTA',
  'FILADOR',
  'FILETADOR',
  'FILOLOGO',
  'FILOSOFO',
  'FILTRADOR DE CERVEJA',
  'FINANCISTA',
  'FISCAL DA PREVIDENCIA SOCIAL',
  'FISCAL DE ARMAZEM',
  'FISCAL DE BANCO',
  'FISCAL DE CAIXA DE SUPERMERCADO',
  'FISCAL DE CONTRIBUIÇOES PREVIDENCIARIAS',
  'FISCAL DE ESTAÇAO (FERROVIAS)',
  'FISCAL DE IBC',
  'FISCAL DE LIMPEZA URBANA',
  'FISCAL DE LINHA',
  'FISCAL DE LOJA',
  'FISCAL DE MAQUINA DE PRODUÇAO',
  'FISCAL DE MOVIMENTO (FERROVIAS)',
  'FISCAL DE OBRAS (EMPRESAS)',
  'FISCAL DE OBRAS SEM ESPECIFICACAO',
  'FISCAL DE OBRAS(SERVICO PUBLICO,AUTARQUIAS)',
  'FISCAL DE ONIBUS',
  'FISCAL DE PORTARIA',
  'FISCAL DE TRAFEGO AEREO',
  'FISCAL DE TRANSPORTE COLETIVO(EXCETO TREM)',
  'FISCAL DE TREM',
  'FISCAL DE TRIBUTOS',
  'FISCAL DE VIACAO',
  'FISCAL DE VIGILANCIA',
  'FISCAL SEM ESPECIFICACAO',
  'FISICO',
  'FISIOLOGISTA',
  'FISIOTERAPEUTA',
  'FITOTECARIO',
  'FLORICULTOR',
  'FLORISTA (FLORES ARTIFICIAIS)',
  'FLORISTA SEM ESPECIFICACAO',
  'FLORISTA(COMERCIO)',
  'FOGUETEIRO',
  'FOGUISTA (CALDEIRA A VAPOR)',
  'FOGUISTA DE EMBARCACOES',
  'FOGUISTA DE TREM',
  'FOGUISTA SEM ESPECIFICACAO',
  'FOLHEADOR DE MOVEIS',
  'FOLHEADOR SEM ESPECIFICACAO',
  'FOLLOW-UP DE PRODUCAO',
  'FONEGRAMISTA',
  'FONOAUDIOLOGO',
  'FORJADOR',
  'FORNEIRO DE ALTO FORNO',
  'FORNEIRO DE CADINHO',
  'FORNEIRO DE CONVERSOR',
  'FORNEIRO DE CUBILO',
  'FORNEIRO DE FAIANCA E PORCELANA',
  'FORNEIRO DE FORJARIA',
  'FORNEIRO DE FORNO-POCO',
  'FORNEIRO DE MANDIOCA',
  'FORNEIRO DE MATERIAL DE CONSTRUCAO',
  'FORNEIRO DE REVERBERO',
  'FORNEIRO DE TRATAMENTO TERMICO',
  'FORNEIRO DE VIDRARIA E CERAMICA',
  'FORNEIRO METALURGICO (1 FUSAO)',
  'FORNEIRO METALURGICO (2 FUSAO)',
  'FORNEIRO PIROMETRISTA',
  'FORNEIRO SEM ESPECIFICACAO',
  'FOSCADOR (DE ESPELHO)',
  'FOSFATIZADOR',
  'FOTOCOPISTA',
  'FOTOGRAFO (FORA EXCECOES)',
  'FOTOGRAFO (FOTOGRAVURA)',
  'FOTOGRAFO DE SERIGRAFIA',
  'FOTOGRAFO DE TRACO',
  'FOTOGRAFO SEM ESPECIFICACAO',
  'FOTOGRAMETRISTA',
  'FOTOGRAVADOR',
  'FOTOIMPRESSOR DE CHAPAS',
  'FOTOLITO,GRAVADOR A',
  'FRENTISTA (CONSTRUCAO CIVIL)',
  'FRENTISTA (POSTO DE GASOLINA)',
  'FRENTISTA SEM ESPECIFICACAO',
  'FRESADOR (FORA EXCECAO)',
  'FRESADOR COPIADOR',
  'FRESADOR SEM ESPECIFICACAO',
  'FRUTEIRO',
  'FRUTICULTOR',
  'FUMICULTOR',
  'FUNCIONARIO AUTARQUISTA',
  'FUNCIONARIO DA JUSTICA',
  'FUNCIONARIO PUBLICO SEM ESPECIFICACAO',
  'FUNCIONARIO PUBLICO SUPERIOR',
  'FUNCIONARIO SEM ESPECIFICACAO',
  'FUNDIDOR DE ESTEREOPLASTICO',
  'FUNDIDOR DE JOALHERIA',
  'FUNDIDOR DE METAIS',
  'FUNDIDOR DE PRESSAO',
  'FUNDIDOR DE VIDRO',
  'FUNDIDOR METALURGICO (1a. FUSAO)',
  'FUNDIDOR METALURGICO (2a. FUSAO)',
  'FUNDIDOR SEM ESPECIFICACAO',
  'FUNDIDOR VIDREIRO',
  'FUNERARIO, AGENTE',
  'FUNILEIRO',
  'FURADOR (FORA EXCECAO)',
  'FURADOR DE PECAS DE CERAMICA',
  'FURADOR SEM ESPECIFICACAO',
  'GAIOLEIRO',
  'GALVANIZADOR',
  'GALVANOPLASTA',
  'GALVANOPLASTA (GRAFICO)',
  'GARCOM',
  'GARI',
  'GARIMPEIRO',
  'GASISTA',
  'GENEALOGISTA',
  'GEOFISICO',
  'GEOGRAFO',
  'GEOLOGO (CURSO SUPERIOR)',
  'GEOLOGO (SEM CURSO SUPERIOR)',
  'GEOMETRA',
  'GEOQUIMICO',
  'GERADORISTA',
  'GERENTE ADMINISTRATIVO',
  'GERENTE AGRICOLA',
  'GERENTE CINEMATOGRAFICO',
  'GERENTE COMERCIAL',
  'GERENTE DE AEROPORTO',
  'GERENTE DE ARMAZEM',
  'GERENTE DE BAR E LANCHONETE',
  'GERENTE DE CAMBIO',
  'GERENTE DE CANTINA',
  'GERENTE DE CINEMA',
  'GERENTE DE COMPRAS',
  'GERENTE DE CONTROLE DE QUALIDADE',
  'GERENTE DE CORREIOS E TELEGRAFOS',
  'GERENTE DE CUSTOS',
  'GERENTE DE DIVISAO DE PECAS',
  'GERENTE DE ENGENHARIA INDUSTRIAL',
  'GERENTE DE FROTA (TRANSPORTE)',
  'GERENTE DE HOTEL',
  'GERENTE DE IMPORTACAO',
  'GERENTE DE LOJA',
  'GERENTE DE MARKETING',
  'GERENTE DE MATERIAIS',
  'GERENTE DE MODERNIZACAO',
  'GERENTE DE ORCAMENTOS',
  'GERENTE DE PATRIMONIO',
  'GERENTE DE PESQUISA E DESENVOLVIMENTO',
  'GERENTE DE PESSOAL',
  'GERENTE DE PLANEJAMENTO E ORGANIZACAO',
  'GERENTE DE PRODUCAO',
  'GERENTE DE PRODUTOS',
  'GERENTE DE PROMOCAO',
  'GERENTE DE PROPAGANDA',
  'GERENTE DE PUBLICIDADE',
  'GERENTE DE RECURSOS HUMANOS',
  'GERENTE DE RELACOES INDUSTRIAIS',
  'GERENTE DE RELACOES PUBLICAS',
  'GERENTE DE RESTAURANTE',
  'GERENTE DE SISTEMAS E METODOS',
  'GERENTE DE TREINAMENTO',
  'GERENTE DE VENDAS',
  'GERENTE FINANCEIRO',
  'GERENTE INDUSTRIAL',
  'GERENTE OPERACIONAL',
  'GERENTE SEM ESPECIFICACAO',
  'GERENTE TECNICO',
  'GERENTE TESOUREIRO',
  'GESSEIRO',
  'GINECOLOGISTA',
  'GOMEIRO',
  'GOVERNADOR',
  'GOVERNANTA',
  'GRAFICO SEM ESPECIFICACAO',
  'GRAMPEADOR',
  'GRAMPEIRO',
  'GRANITADOR',
  'GRANITEIRO',
  'GRANJEIRO',
  'GRANULADOR DE CAFE',
  'GRAVADOR (ENCADERNACAO)',
  'GRAVADOR (FOTOGRAVURA)',
  'GRAVADOR (IMPRESSAO)',
  'GRAVADOR (JOALHERIA)',
  'GRAVADOR A AGUA FORTE',
  'GRAVADOR ARTISTICO',
  'GRAVADOR COM PANTOGRAFO',
  'GRAVADOR DE AUTOTIPIA',
  'GRAVADOR DE INSCRICAO EM PEDRA',
  'GRAVADOR DE OSSO',
  'GRAVADOR DE PEDRA',
  'GRAVADOR DE PEDRAS LITOGRAFICAS',
  'GRAVADOR DE PLASTICO',
  'GRAVADOR DE RELEVOS EM PEDRA',
  'GRAVADOR DE ROTOGRAVURA',
  'GRAVADOR DE TRACO',
  'GRAVADOR DE V.D.',
  'GRAVADOR DE VIDRO',
  'GRAVADOR SEM ESPECIFICACAO',
  'GRAVADOR TRICOMISTA',
  'GRAXEIRO',
  'GROSADOR',
  'GUARDA DE CAMPING',
  'GUARDA DE PRESIDIO',
  'GUARDA DE RETIRO (PECUARIA)',
  'GUARDA DE SEGURANCA',
  'GUARDA DE TRANSITO',
  'GUARDA DE TRIBUNAIS',
  'GUARDA DE VIGILANCIA',
  'GUARDA SEM ESPECIFICACAO',
  'GUARDA-CANCELA',
  'GUARDA-CHAVES',
  'GUARDA-CIVIL',
  'GUARDA-COSTAS',
  'GUARDA-FERRAMENTAS',
  'GUARDA-FERROVIARIO',
  'GUARDA-FIOS',
  'GUARDA-FLORESTAL',
  'GUARDA-FREIOS',
  'GUARDA-LIVROS (CURSO SUPERIOR)',
  'GUARDA-LIVROS (SEM CURSO SUPERIOR)',
  'GUARDA-MUNICIPAL',
  'GUARDA-NOTURNO',
  'GUARDA-NOTURNO PARTICULAR',
  'GUARDA-PORTUARIO',
  'GUARDA-RODOVIARIO',
  'GUARDA-ROUPA',
  'GUARDA-SANITARIO',
  'GUARDADOR DE VEICULOS',
  'GUIA',
  'GUILHOTINISTA',
  'GUINCHEIRO',
  'GUINDASTEIRO',
  'HIDRAULICO (SEM CURSO SUPERIOR)',
  'HIDROBIOLOGISTA',
  'HIDROGENADOR DE OLEOS E GORDURAS',
  'HIDROMETRISTA',
  'HIGIENISTA (CURSO SUPERIOR)',
  'HIGIENIZADOR DE LEITE',
  'HISTORIADOR',
  'HORTICULTOR',
  'HOTELEIRO',
  'ICTIOLOGO',
  'IGUALADOR DE PELES',
  'ILUMINADOR (TEATRO E TV)',
  'ILUSTRADOR',
  'IMEDIATO(NAVEGACAO)',
  'IMPERMEABILIZADOR',
  'IMPOSITOR',
  'IMPREGNADOR DE BORRACHA',
  'IMPREGNADOR DE MADEIRA',
  'IMPREGNADOR DE PAPEL',
  'IMPRESSOR (MULTILITH)',
  'IMPRESSOR (PRENSA CILINDRICA)',
  'IMPRESSOR (SERIGRAFIA)',
  'IMPRESSOR DE OFFSET',
  'IMPRESSOR DE PAPEIS DECORATIVOS',
  'IMPRESSOR DE ROTOGRAVURA',
  'IMPRESSOR DE SILK-SCREEN',
  'IMPRESSOR GRAFICO MANUAL',
  'IMPRESSOR LITOGRAFICO',
  'IMPRESSOR MINERVISTA',
  'IMPRESSOR SEM ESPECIFICACAO',
  'IMPRESSOR TIPOGRAFICO',
  'INATIVO',
  'INCUBADOR',
  'INDEXADOR',
  'INDUSTRIAL',
  'INDUSTRIARIO SEM ESPECIFICACAO',
  'INFORMANTE COMERCIAL',
  'INFORMANTE DE CADASTRO',
  'INFORMANTE SEM ESPECIFICACAO',
  'INJETOR DE PLASTICO',
  'INSEMINADOR',
  'INSPETOR COMERCIAL',
  'INSPETOR DE ALUNOS',
  'INSPETOR DE ARMAZENAMENTO DE GRAOS',
  'INSPETOR DE AVARIAS',
  'INSPETOR DE AVIAO',
  'INSPETOR DE CALDEIRARIA',
  'INSPETOR DE CAMPO',
  'INSPETOR DE CENTROS DE ABASTECIMENTO',
  'INSPETOR DE COBRANCA',
  'INSPETOR DE COMERCIALIZACAO DE PRODUTOS',
  'INSPETOR DE ESCOLA',
  'INSPETOR DE ESTACAO (FERROVIAS)',
  'INSPETOR DE ESTAMPARIA',
  'INSPETOR DE FERRAMENTAS',
  'INSPETOR DE FUMO',
  'INSPETOR DE FUNDICAO',
  'INSPETOR DE HIGIENE E SEGURANCA DO TRABALHO',
  'INSPETOR DE INSTALACOES INDUSTRIAIS',
  'INSPETOR DE LABORATORIO',
  'INSPETOR DE LICENCA URBANA',
  'INSPETOR DE LICENCIAMENTO DE VEICULOS',
  'INSPETOR DE LINHA DE PRODUCAO',
  'INSPETOR DE MATERIAL',
  'INSPETOR DE MATRIZES',
  'INSPETOR DE METEOROLOGIA',
  'INSPETOR DE MOVIMENTO (FERROVIAS)',
  'INSPETOR DE OBRAS (SERVICO PUBLICO)',
  'INSPETOR DE OBRAS SEM ESPECIFICACAO',
  'INSPETOR DE POLICIA',
  'INSPETOR DE PREVENCAO CONTRA INCENDIO',
  'INSPETOR DE PRODUCAO',
  'INSPETOR DE PROVAS',
  'INSPETOR DE QUALIDADE',
  'INSPETOR DE RECEBIMENTO',
  'INSPETOR DE ROTAS',
  'INSPETOR DE SEGURANCA',
  'INSPETOR DE SEGURANCA DO TRABALHO',
  'INSPETOR DE SEGURANCA INDUSTRIAL',
  'INSPETOR DE SERVICO DE TRANSPORTE RODOVIARIO',
  'INSPETOR DE TRABALHO',
  'INSPETOR DE TRAFEGO (EXCETO FERROVIAS)',
  'INSPETOR DE TRAFEGO (FERROVIAS)',
  'INSPETOR DE TRANSPORTE (FERROVIAS)',
  'INSPETOR DE TRANSPORTE COLETIVO',
  'INSPETOR DE TREM',
  'INSPETOR DE USINAGEM',
  'INSPETOR DE VEICULOS',
  'INSPETOR DE VENDAS',
  'INSPETOR DE VIGILANCIA',
  'INSPETOR GERAL',
  'INSPETOR SEM ESPECIFICACAO',
  'INSPETOR TECNICO DE VENDAS',
  'INSPETOR TECNICO SEM ESPECIFICACAO',
  'INSPETOR VISUAL (FUNDAMENTAL)',
  'INST.ISOL.TERM.(CLIMATIZACAO E REFRIGERACAO)',
  'INST.ISOLANTES TERM.(CALDEIRAS E TUBULACOES)',
  'INSTALADOR DE CABOS E CORDAS (VEICULOS)',
  'INSTALADOR DE EQUIPA/ DE LEVANTA/(CONSTRUCAO)',
  'INSTALADOR DE FERRAMENTAS (ELETRONICAS)',
  'INSTALADOR DE ISOLANTES ACUSTICOS',
  'INSTALADOR DE LINHAS ELETRICAS',
  'INSTALADOR DE MATERIAL ISOLANTE',
  'INSTALADOR DE REDES DE ESGOTO',
  'INSTALADOR DE TELEGRAFO E TELEFONIA',
  'INSTALADOR DE TUBULACOES EM GERAL',
  'INSTALADOR ELETRICISTA (REDE)',
  'INSTALADOR ELETRICISTA SEM ESPECIFICACAO',
  'INSTALADOR HIDRAULICO',
  'INSTALADOR SEM ESPECIFICACAO',
  'INSTRUMENTADOR CIRURGICO',
  'INSTRUMENTISTA (MUSICO)',
  'INSTRUMENTISTA DE AVIOES',
  'INSTRUMENTISTA ELETRONICO (MONTAGEM)',
  'INSTRUMENTISTA MECANICO',
  'INSTRUTOR DE AUTO-ESCOLA',
  'INSTRUTOR DE BALE',
  'INSTRUTOR DE EDUCACAO FISICA',
  'INSTRUTOR DE ESPORTE',
  'INSTRUTOR DE FORMACAO PROFISSIONAL',
  'INSTRUTOR DE OFICIO',
  'INSTRUTOR DE VOO',
  'INSTRUTOR SEM ESPECIFICACAO',
  'INSTRUTOR TECNICO',
  'INTENDENTE DE BORDO',
  'INTERCALADOR SEM ESPECIFICACAO',
  'INTERPRETE',
  'INVALIDO',
  'INVENTARIANTE (ABASTECIMENTO)',
  'INVENTARIANTE FLORESTAL',
  'INVENTARIANTE SEM ESPECIFICACAO',
  'INVERNEIRO',
  'INVESTIGADOR DE CADASTRO',
  'INVESTIGADOR DE POLICIA',
  'INVESTIGADOR SEM ESPECIFICACAO',
  'JACALEIRO',
  'JARDINEIRO (AGRICULTURA)',
  'JARDINEIRO (RESIDENCIAS)',
  'JARDINEIRO SEM ESPECIFICACAO',
  'JATEADOR',
  'JATISTA',
  'JOALHEIRO',
  'JOQUEI',
  'JORNALEIRO',
  'JORNALISTA',
  'JUIZ (FORA EXCECAO)',
  'JUIZ DE LINHA',
  'JUIZ SEM ESPECIFICACAO',
  'JURISTA',
  'KARDEXISTA',
  'LABORATORISTA (ANALISES CLINICAS)',
  'LABORATORISTA INDUSTRIAL EM GERAL',
  'LABORATORISTA QUIMICO',
  'LABORATORISTA SEM ESPECIFICACAO',
  'LACRADOR',
  'LADRILEJADOR',
  'LADRILHEIRO',
  'LADRILHISTA',
  'LAGAREIRO',
  'LAJEADOR',
  'LAMINADOR DE ACO',
  'LAMINADOR DE CHAPAS DE VIDRO',
  'LAMINADOR DE CRISTAIS DE OPTICA',
  'LAMINADOR DE MADEIRA',
  'LAMINADOR DE MASSAS ALIMENTICIAS',
  'LAMINADOR DE METAIS',
  'LAMINADOR DE PLASTICO',
  'LAMINADOR SEM ESPECIFICACAO',
  'LAMPISTA',
  'LANCADOR DE CONTAS',
  'LANCHEIRO',
  'LANTERNEIRO',
  'LAPIDADOR DE JOIAS',
  'LAPIDADOR DE VIDROS E CRISTAIS',
  'LAPIDADOR SEM ESPECIFICACAO',
  'LAQUEADOR DE MOVEIS',
  'LATOEIRO',
  'LAVADEIRA',
  'LAVADOR DE COUROS',
  'LAVADOR DE FIBRAS',
  'LAVADOR DE FRASCOS',
  'LAVADOR DE LA',
  'LAVADOR DE LOUCA',
  'LAVADOR DE MATERIAL QUIMICO',
  'LAVADOR DE MINERAIS',
  'LAVADOR DE VASILHAME',
  'LAVADOR DE VEICULOS',
  'LAVADOR SEM ESPECIFICACAO',
  'LAVANDEIRO',
  'LAVRADOR (EMPREGADO)',
  'LAVRADOR (PROPRIETARIO)',
  'LAVRADOR SEM ESPECIFICACAO',
  'LEILOEIRO',
  'LEITEIRO',
  'LEITOR DE VAZAO',
  'LEITURISTA',
  'LENHADOR',
  'LENHEIRO',
  'LETRISTA (LETREIROS)',
  'LETRISTA (MUSICO)',
  'LICORISTA',
  'LIDER DE FABRICACAO',
  'LIDER DE MONTAGEM',
  'LIDER DE SEGURANCA',
  'LIDER DE TURMA (EXCETO AGRICOLA)',
  'LIDER DE USINAGEM',
  'LIDER SEM ESPECIFICACAO',
  'LIGADOR DE TELEFONES',
  'LIMADOR',
  'LIMPADEIRA',
  'LIMPADOR A SECO',
  'LIMPADOR DE CHAMINES',
  'LIMPADOR DE ESPULAS',
  'LIMPADOR DE FACHADAS',
  'LIMPADOR DE FACHADAS (A JATO)',
  'LIMPADOR DE FRUTAS E LEGUMES',
  'LIMPADOR DE JANELAS',
  'LIMPADOR DE METAIS',
  'LIMPADOR DE PISOS',
  'LIMPADOR DE TEAR',
  'LIMPADOR DE VEICULOS',
  'LIMPADOR SEM ESPECIFICACAO',
  'LINGOTADOR',
  'LINOTIPISTA',
  'LITOGRAFO',
  'LIVREIRO',
  'LIXADOR DE COUROS E PELES',
  'LIXADOR DE PAPEL',
  'LIXADOR DE PINTURA',
  'LIXADOR DE SALTOS',
  'LIXADOR DE SOLAS',
  'LIXADOR SEM ESPECIFICACAO',
  'LIXEIRO',
  'LOCUTOR',
  'LOMBADOR',
  'LOTEADOR',
  'LUBRIFICADOR DE EMBARCACOES',
  'LUBRIFICADOR DE MAQUINAS',
  'LUBRIFICADOR DE VEICULOS (EXCETO EMBARCACOES)',
  'LUBRIFICADOR INDUSTRIAL',
  'LUBRIFICADOR SEM ESPECIFICACAO',
  'LUDLOWISTA',
  'LUSTRADOR DE BORRACHA',
  'LUSTRADOR DE CALCADOS (INDUSTRIA)',
  'LUSTRADOR DE MOVEIS',
  'LUSTRADOR DE PISOS',
  'LUSTRADOR SEM ESPECIFICACAO',
  'LUVEIRO',
  'MACARIQUEIRO',
  'MACAROQUEIRO',
  'MACARRONEIRO (INDUSTRIA)',
  'MACHADEIRO',
  'MACHEIRO',
  'MADEREIRO (PRODUCAO)',
  'MADEREIRO (SUBSOLO)',
  'MAESTRO',
  'MAGAREFE',
  'MAGISTRADO FEDERAL',
  'MAITRE',
  'MALABARISTA',
  'MALEIRO',
  'MALETEIRO',
  'MALHARISTA',
  'MALTEADOR (GERMINACAO)',
  'MALVEIRO',
  'MANCHEIRO',
  'MANDIOQUEIRO',
  'MANDRILADOR',
  'MANEQUIM',
  'MANICURO',
  'MANIPULADOR (LOTERIA ESPORTIVA)',
  'MANIPULADOR DE CHAPAS RADIOGRAFICAS',
  'MANIPULADOR DE CORRESPONDENCIA',
  'MANIPULADOR DE DROGAS (INDUSTRIA TEXTIL)',
  'MANIPULADOR DE MATERIAIS (INDUSTRIA)',
  'MANIPULADOR DE SOL. E SUBSTANCIAS QUIMICAS',
  'MANIPULADOR DE SOLUCOES (MEDICAMETOS)',
  'MANIPULANTE (CORRESPONDENCIA)',
  'MANOBRADOR (FERROVIAS)',
  'MANOBREIRO',
  'MANOBRISTA (ESTACIONAMENTO)',
  'MANOBRISTA (FABRICA)',
  'MANOBRISTA (FERROVIA)',
  'MANOBRISTA (GARAGEM)',
  'MANOBRISTA SEM ESPECIFICACAO',
  'MANTEIGUEIRO',
  'MANUTENCAO (EDIFICIO)',
  'MANUTENCAO (INDUSTRIA)',
  'MANUTENCAO SEM ESPECIFICACAO',
  'MAQUETISTA',
  'MAQUILADOR',
  'MAQUINISTA (INDUSTRIA DE BALAS)',
  'MAQUINISTA DE ABRIDOR DE FIBRAS',
  'MAQUINISTA DE CALANDRA (TECIDOS)',
  'MAQUINISTA DE CARDA',
  'MAQUINISTA DE CENARIO',
  'MAQUINISTA DE COMPRESSOR DE AR',
  'MAQUINISTA DE CONICALEIRA',
  'MAQUINISTA DE ELEVADOR (MINAS)',
  'MAQUINISTA DE EMBARCACAO',
  'MAQUINISTA DE ENGOMADEIRA DE URDUME',
  'MAQUINISTA DE ESPULADEIRA',
  'MAQUINISTA DE FIACAO',
  'MAQUINISTA DE FIANDEIRA',
  'MAQUINISTA DE FILATORIO',
  'MAQUINISTA DE LAMINADEIRA (TECIDOS)',
  'MAQUINISTA DE MACAROQUEIRA',
  'MAQUINISTA DE MARCENARIA',
  'MAQUINISTA DE PASSADOR',
  'MAQUINISTA DE PENTEADEIRA',
  'MAQUINISTA DE RAMEUSE',
  'MAQUINISTA DE RAMPA (MINAS)',
  'MAQUINISTA DE RETORCEDEIRA',
  'MAQUINISTA DE REUNIDEIRA',
  'MAQUINISTA DE TREM',
  'MAQUINISTA DE URDIDEIRA',
  'MAQUINISTA INDUSTRIAL SEM ESPECIFICACAO',
  'MAQUINISTA SEM ESPECIFICACAO',
  'MARCADOR (PECUARIA)',
  'MARCADOR DE EMBALAGEM',
  'MARCADOR DE FARDOS',
  'MARCADOR DE JORNAL',
  'MARCADOR DE ROUPA',
  'MARCADOR DESPORTIVO',
  'MARCADOR SEM ESPECIFICACAO',
  'MARCENEIRO (ARTIGOS ESPORTIVOS)',
  'MARCENEIRO (CACHIMBOS)',
  'MARCENEIRO (FORA EXCECOES)',
  'MARCENEIRO (TAMANCOS)',
  'MARCENEIRO SEM ESPECIFICACAO',
  'MARCHETEIRO',
  'MARCINISTA DE PENTEADEIRA',
  'MARGEADOR',
  'MARINHEIRO',
  'MARISCADOR',
  'MARISQUEIRO',
  'MARITIMO',
  'MARMORISTA DE CONSTRUCAO',
  'MARMORISTA SEM ESPECIFICACAO',
  'MARRETEIRO (COMERCIO)',
  'MARRETEIRO (PEDREIRA)',
  'MARRUEIRO',
  'MARTELEIRO',
  'MARTELETEIRO',
  'MASCATE',
  'MASSAGISTA',
  'MASSEADOR (CERAMICA E VIDRARIA)',
  'MASSEIRO (MASSAS ALIMENTICIAS)',
  'MATA-MOSQUITO',
  'MATADOR',
  'MATEIRO (EXTRACAO DE MADEIRA)',
  'MATEIRO (GUARDA-FLORESTAL)',
  'MATEIRO (GUIA FLORESTAL)',
  'MATEIRO (INVENTARIO FLORESTAL)',
  'MATEIRO SEM ESPECIFICACAO',
  'MATEMATICO',
  'MATEMATICO-ESTATISTICO',
  'MATRIZEIRO',
  'MEADEIRO',
  'MECANICO AJUSTADOR',
  'MECANICO DE AERONAVES',
  'MECANICO DE APARELHOS DE PRECISAO',
  'MECANICO DE BICICLETA',
  'MECANICO DE CARRO',
  'MECANICO DE FOGAO',
  'MECANICO DE MANUTENCAO (AERONAVES)',
  'MECANICO DE MANUTENCAO (CENTRAL TELEFONICA)',
  'MECANICO DE MANUTENCAO (MAQUINAS)',
  'MECANICO DE MANUTENCAO (VEICULOS)',
  'MECANICO DE MANUTENCAO INDUSTRIAL',
  'MECANICO DE MANUTENCAO SEM ESPECIFICACAO',
  'MECANICO DE MAQUINAS (FORA EXCECOES)',
  'MECANICO DE MAQUINAS DE COSTURA',
  'MECANICO DE MOTOR DE AVIAO',
  'MECANICO DE MOTOR DE CARRO',
  'MECANICO DE MOTORES (EXCETO AVIAO E CARRO)',
  'MECANICO DE REFRIGERACAO',
  'MECANICO DE SUSPENSAO',
  'MECANICO DE TELEIMPRESSORES',
  'MECANICO DE TELETIPO',
  'MECANICO DE VEICULOS (EXCETO AVIAO)',
  'MECANICO DE VOO',
  'MECANICO GRAFICO',
  'MECANICO HIDRAULICO',
  'MECANICO INDUSTRIAL',
  'MECANICO INSTRUMENTISTA',
  'MECANICO LUBRIFICADOR (EMBARCACOES)',
  'MECANICO MONTADOR (INSTRUMENTOS DE PRECISAO)',
  'MECANICO MONTADOR (MAQUINAS)',
  'MECANICO PLAINADOR',
  'MECANICO SEM ESPECIFICACAO',
  'MECANICO TORNEIRO',
  'MECANOGRAFO',
  'MEDICO',
  'MEDICO VETERINARIO',
  'MEDIDOR DE ARVORES',
  'MEDIDOR DE COUROS',
  'MEDIDOR DE ESTRUTURAS NAVAIS',
  'MEDIDOR DE MADEIRA',
  'MEDIDOR DE PANO',
  'MEDIDOR DE PECAS (TECELAGEM)',
  'MEDIDOR DE TERRA',
  'MEDIDOR SEM ESPECIFICACAO',
  'MENSAGEIRO (ESCRITORIO)',
  'MENSAGEIRO (FORA EXCECOES)',
  'MENSAGEIRO (HOTEL)',
  'MENSAGEIRO SEM ESPECIFICACAO',
  'MERCADOLOGO',
  'MERCEALIZADOR',
  'MERCERIZADOR DE FIOS E TECIDOS',
  'MERENDEIRO',
  'MERGULHADOR',
  'MESTRE (ELETRICIDADE, GAS E AGUA)',
  'MESTRE (ESGOTO SANITARIO)',
  'MESTRE (EXTRACAO DE SAL)',
  'MESTRE (FONTES HIDROMINERAIS)',
  'MESTRE (FORA EXCECOES)',
  'MESTRE (LINHAS DE ALTA TENSAO)',
  'MESTRE (MINAS E PEDREIRAS)',
  'MESTRE (PETROLEO E GAS)',
  'MESTRE ARRAIS',
  'MESTRE DE CANOA',
  'MESTRE SEM ESPECIFICACAO',
  'MESTRE-DE-OBRAS',
  'METALIZADOR',
  'METALOGRAFISTA',
  'METALURGICO SEM ESPECIFICACAO',
  'METEREOLOGISTA (CURSO SUPERIOR)',
  'METEREOLOGISTA (SEM CURSO SUPERIOR)',
  'METROLOGISTA',
  'METROVIARIO SEM ESPECIFICACAO',
  'MICROBIOLOGISTA',
  'MILITAR',
  'MIMEOGRAFISTA',
  'MINEIRO',
  'MINERADOR',
  'MINIATURISTA',
  'MINISTRO (DIPLOMACIA)',
  'MINISTRO DE CULTO RELIGIOSO',
  'MINISTRO DE ESTADO',
  'MISSIONARIO',
  'MISTURADOR (REFINO DE PETROLEO)',
  'MISTURADOR DE CAFE',
  'MISTURADOR DE CHA',
  'MISTURADOR DE FIBRAS',
  'MISTURADOR DE FUMO',
  'MISTURADOR DE GRAOS',
  'MISTURADOR DE TINTAS',
  'MISTURADOR DE VIDRO',
  'MISTURADOR DE VINHO',
  'MIXADOR',
  'MOCO DE CONVES',
  'MODELADOR CERAMISTA',
  'MODELADOR DE MADEIRA',
  'MODELADOR DE METAL',
  'MODELADOR DE PNEUMATICOS',
  'MODELADOR SEM ESPECIFICACAO',
  'MODELISTA DE CALCADOS',
  'MODELISTA DE CHAPEUS',
  'MODELISTA DE MADEIRA',
  'MODELISTA DE ROUPAS',
  'MODELISTA PELETEIRO',
  'MODELISTA SEM ESPECIFICACAO',
  'MODELO ARTISTICO',
  'MODELO DE MODAS',
  'MODELO FOTOGRAFICO',
  'MODELO SEM ESPECIFICACAO',
  'MODISTA',
  'MOEDOR DE CACAU',
  'MOEDOR DE CAFE',
  'MOEDOR DE SAL',
  'MOINHEIRO',
  'MOLDADOR A MAO',
  'MOLDADOR A MAQUINA',
  'MOLDADOR CERAMISTA',
  'MOLDADOR DE ABRASIVOS',
  'MOLDADOR DE BLOCOS',
  'MOLDADOR DE BORRACHA',
  'MOLDADOR DE CERAMICA',
  'MOLDADOR DE CHAPEUS',
  'MOLDADOR DE CORPOS DE PROVA',
  'MOLDADOR DE DIAMANTES INDUSTRIAIS',
  'MOLDADOR DE LENTES',
  'MOLDADOR DE METAIS',
  'MOLDADOR DE PLASTICO',
  'MOLDADOR EM AREIA',
  'MOLDADOR EM BANCADA',
  'MOLDADOR MECANICO',
  'MOLDADOR SEM ESPECIFICACAO',
  'MOLDEADOR DE MANTEIGA',
  'MOLEIRO (FORA EXCECOES)',
  'MOLEIRO (TRATAMENTOS QUIMICOS)',
  'MOLEIRO DE MINERIOS',
  'MOLEIRO SEM ESPECIFICACAO',
  'MOLHADOR DE FUMO',
  'MOLHEIRO',
  'MONITOR DE BRAILLE',
  'MONITOR DE EDUCACAO FISICA',
  'MONITOR DE MENORES',
  'MONITOR SEM ESPECIFICACAO',
  'MONOTIPISTA',
  'MONT.ARTEFADOS DE COURO(EXC.ROUPAS E CALCADO)',
  'MONT.TURBINAS(EXCETO AERONAVES E EMBARCACOES)',
  'MONTADOR APARELHOS P/TRANSPORTES DE TRONCOS',
  'MONTADOR DE ANDAIMES',
  'MONTADOR DE APARELHOS ELETRICOS',
  'MONTADOR DE APARELHOS ELETRONICOS',
  'MONTADOR DE ARMACOES DE FERRO',
  'MONTADOR DE ARTIGOS DE MADEIRA',
  'MONTADOR DE BALANCAS',
  'MONTADOR DE CABOS E PONTES',
  'MONTADOR DE CAIXAS',
  'MONTADOR DE CALCADOS',
  'MONTADOR DE CARROS (EM SERIE)',
  'MONTADOR DE CENTRAL TELEFONICA',
  'MONTADOR DE CLICHES',
  'MONTADOR DE CONTADORES DE CATRACA',
  'MONTADOR DE EDIFICACOES PRE-FABRICADAS',
  'MONTADOR DE EQUIPAMENTO DE LEVANTAMENTO',
  'MONTADOR DE EQUIPAMENTO DE TELECOMUNICACOES',
  'MONTADOR DE EQUIPAMETO ELETRICO',
  'MONTADOR DE EQUIPAMETO ELETRONICO',
  'MONTADOR DE EST.METALICAS (EXCETO AERONAVES)',
  'MONTADOR DE ESTRUTURAS METALICAS (AERONAVES)',
  'MONTADOR DE FECHADURAS',
  'MONTADOR DE FERRAMENTAS',
  'MONTADOR DE FILMES',
  'MONTADOR DE FOTOLITO',
  'MONTADOR DE INSTALACOES DE CALEFACAO',
  'MONTADOR DE INSTRUMENTOS DE OTICA',
  'MONTADOR DE INSTRUMENTOS DE PRECISAO',
  'MONTADOR DE MAQUINAS',
  'MONTADOR DE MOTORES',
  'MONTADOR DE MOVEIS',
  'MONTADOR DE PAINEIS DE CONTROLE',
  'MONTADOR DE PECAS NAO ESPECIFICADAS',
  'MONTADOR DE PNEUS',
  'MONTADOR DE POLEAMES',
  'MONTADOR DE PRODUCAO',
  'MONTADOR DE PRODUTOS DE BORRACHA',
  'MONTADOR DE PRODUTOS DE PLASTICO',
  'MONTADOR DE ROLOS',
  'MONTADOR DE TAXIMETROS',
  'MONTADOR DE VELAMES',
  'MONTADOR METALURGICO',
  'MONTADOR NAVAL',
  'MONTADOR SEM ESPECIFICACAO',
  'MORDOMO',
  'MOROTAICO',
  'MOSAISTA',
  'MOTORISTA (FORA EXCECOES)',
  'MOTORISTA DE BARCO',
  'MOTORISTA DE EMPILHADEIRA',
  'MOTORISTA DE PESCA',
  'MOTORISTA SEM ESPECIFICACAO',
  'MOTORNEIRO (MINAS)',
  'MUSEOLOGO',
  'MUSICO',
  'MUSICOLOGO',
  'NARRADOR ESPORTIVO',
  'NAVEGADOR DE AERONAVES',
  'NEGATIVISTA',
  'NEUROLOGISTA',
  'NIQUELADOR',
  'NIVELADOR',
  'NORMALIZADOR DE METAIS',
  'NOTICIARISTA (TELEJORNAL)',
  'NOTISTA',
  'NOVELISTA',
  'NUTRICIONISTA',
  'OBSERVADOR METALURGICO',
  'OBSTETRA',
  'OBSTETRIZ (CURSO SUPERIOR)',
  'OBSTETRIZ (SEM CURSO SUPERIOR)',
  'OCEANOGRAFO',
  'ODONTOLOGO (CURSO SUPERIOR)',
  'OFFICE-BOY',
  'OFICIAL ADMINISTRATIVO (SERVICO PUBLICO)',
  'OFICIAL DE COBERTA',
  'OFICIAL DE FARMACIA',
  'OFICIAL DE GABINETE',
  'OFICIAL DE JUSTICA',
  'OFICIAL DE MANUTENCAO',
  'OFICIAL DE NAVEGACAO MARITIMA E INTERIOR',
  'OFICIAL DE SERVICOS DE ESGOTO',
  'OFICIAL HIDRAULICO',
  'OFICIAL MAQUINISTA (EMBARCACOES)',
  'OFTALMOLOGISTA',
  'OLEIRO',
  'OLHEIRO DE PESCA',
  'OPACADOR',
  'OPERADOR AGRICOLA',
  'OPERADOR CINEMATOGRAFICO (PROJECAO)',
  'OPERADOR CONTABIL',
  'OPERADOR DE ABREUGRAFIA',
  'OPERADOR DE ACOES',
  'OPERADOR DE BALANCA',
  'OPERADOR DE BOLSA',
  'OPERADOR DE BOMBEAMENTO (ESTACAO DE)',
  'OPERADOR DE BRITADEIRA',
  'OPERADOR DE CALDEIRA',
  'OPERADOR DE CAMBIO',
  'OPERADOR DE CAMERA CINEMATOGRAFICA',
  'OPERADOR DE CENTRIFUGADORA',
  'OPERADOR DE COMPOSER',
  'OPERADOR DE COMPUTADOR',
  'OPERADOR DE CONTAS CORRENTES',
  'OPERADOR DE COSMETICOS',
  'OPERADOR DE DADOS',
  'OPERADOR DE ELETROEROSAO',
  'OPERADOR DE EMPILHADEIRA',
  'OPERADOR DE EQUIPAMENTO NAO ESPECIFICADO',
  'OPERADOR DE EXAUSTOR',
  'OPERADOR DE FILMES',
  'OPERADOR DE FILTRAGEM',
  'OPERADOR DE FORNO',
  'OPERADOR DE FOTOMECANICA',
  'OPERADOR DE GUINDASTE',
  'OPERADOR DE I.O',
  'OPERADOR DE INJETORA DE PLASTICO',
  'OPERADOR DE LIOFILIZACAO',
  'OPERADOR DE LOCOMOTIVA',
  'OPERADOR DE MAQUINA DE DOBRAR CHAPAS',
  'OPERADOR DE MAQUINA MISTURADEIRA',
  'OPERADOR DE MAQUINA NAO ESPECIFICADA',
  'OPERADOR DE MAQUINAS AGRICOLAS',
  'OPERADOR DE MESA E BOLSA',
  'OPERADOR DE MICROONDAS',
  'OPERADOR DE OFFSET',
  'OPERADOR DE PAINEL DE CALCINACAO',
  'OPERADOR DE PAINEL DE COQUERIA',
  'OPERADOR DE PAINEL DE REFIO DE PETROLEO',
  'OPERADOR DE PAINEL DE SINTERIZACAO',
  'OPERADOR DE PAINEL NAO ESPECIFICADO',
  'OPERADOR DE PERFURATRIZ',
  'OPERADOR DE PONTE ROLANTE',
  'OPERADOR DE PRODUCAO',
  'OPERADOR DE RAIOS X',
  'OPERADOR DE REFINARIA (EXTRACAO MINERAL)',
  'OPERADOR DE SERRA (EXPLORACAO FLORESTAL)',
  'OPERADOR DE SISTEMA DE REVERSAO',
  'OPERADOR DE SOM',
  'OPERADOR DE SULFITACAO',
  'OPERADOR DE TELECOMUNICACOES',
  'OPERADOR DE TELEIMPRESSOR',
  'OPERADOR DE TELEX',
  'OPERADOR DE TERMINAL (PROCESSAMENTO DE DADOS)',
  'OPERADOR DE TIROTEX',
  'OPERADOR DE TRATAMENTO DE AGUA',
  'OPERADOR DE TREMOEUSE',
  'OPERADOR DE UTILIDADES',
  'OPERADOR DE VALORES',
  'OPERADOR DE VIDEO',
  'OPERADOR DE XEROX',
  'OPERADOR INDUSTRIAL',
  'OPERADOR RADIAL',
  'OPERADOR SEM ESPECIFICACAO',
  'OPERARIO SEM ESPECIFICACAO',
  'OPTICO',
  'OPTOMETRISTA',
  'ORCAMENTISTA',
  'ORDENHADOR',
  'ORDITRIZ',
  'ORGANEIRO',
  'ORIENTADOR EDUCACIONAL',
  'ORIENTADOR INFANTIL',
  'ORIENTADOR PEDAGOGICO',
  'ORIENTADOR PROFISSIONAL',
  'ORIENTADOR SEM ESPECIFICACAO',
  'ORNAMENTISTA (PEDRA)',
  'ORNITOLOGO',
  'ORQUESTRADOR',
  'ORTOPEDISTA',
  'ORTOPTICO',
  'ORTOPTISTA',
  'ORTOTICO',
  'OTICO',
  'OTORRINOLARINGOLOGISTA',
  'OURELADOR DE TECIDOS',
  'OURIVES',
  'OVERLOQUISTA',
  'OXICORTADOR',
  'OXIDADOR',
  'PADEIRO',
  'PADRONISTA DE BARRACAS',
  'PADRONISTA DE CALCADOS',
  'PADRONISTA DE CHAPEUS',
  'PADRONISTA DE MATERIAIS',
  'PADRONISTA DE PELETERIA',
  'PADRONISTA DE ROUPAS',
  'PADRONISTA DE VELAS (EMBARCACOES)',
  'PAGINADOR',
  'PAISAGISTA',
  'PALECIONADOR',
  'PALEONTOLOGO',
  'PALHACO',
  'PANIFICADOR',
  'PANTOGRAFISTA',
  'PAPELEIRO (FABRICACAO MANUAL)',
  'PAPILOSCOPISTA POLICIAL',
  'PARAFINEIRO',
  'PARQUETEIRO',
  'PARTEIRA (CURSO SUPERIOR)',
  'PARTEIRA (SEM CURSO SUPERIOR)',
  'PARTEIRA SEM ESPECIFICACAO',
  'PASSADERA',
  'PASSADOR DE ROUPAS',
  'PASSADOR SEM ESPECIFICACAO',
  'PASSAMANEIRO',
  'PASSARINHEIRO',
  'PASTELEIRO',
  'PASTEURIZADOR',
  'PASTILHEIRO',
  'PASTOR PROTESTANTE',
  'PASTOREADOR',
  'PATENTEADOR',
  'PATISSEIRO',
  'PATOLOGISTA CLINICO',
  'PATRAO DE PESCA',
  'PATROLISTA',
  'PATRULHEIRO AGRICOLA',
  'PATRULHEIRO RODOVIARIO',
  'PAUTADOR',
  'PAVIMENTADOR (A MAQUINA)',
  'PAVIMENTADOR (PAVIMENTO CONTINUO)',
  'PAVIMENTADOR SEM ESPECIFICACAO',
  'PAVONADOR DE METAIS',
  'PEAO AGRICOLA',
  'PEAO DE ESTABULO',
  'PEAO DE PECUARIA',
  'PECUARISTA',
  'PEDAGOGO',
  'PEDIATRA',
  'PEDICURO',
  'PEDREIRO (ESGOTOS E RECEPCAO DE AGUAS)',
  'PEDREIRO (FORA EXCECOES)',
  'PEDREIRO MARMORISTA',
  'PEDREIRO SEM ESPECIFICACAO',
  'PEIXEIRO',
  'PELETEIRO',
  'PELICULADOR',
  'PENSIONISTA',
  'PENTEADOR (DE FIBRAS)',
  'PERFILADOR SEM ESPECIFICACAO',
  'PERFUMISTA (CURSO SUPERIOR)',
  'PERFUMISTA (SEM CURSO SUPERIOR)',
  'PERFURADOR (CARTOES E FITAS)',
  'PERFURADOR (LOTERIA ESPORTIVA)',
  'PERFURADOR DE CARTOES JACQUARD',
  'PERFURADOR DE DADOS',
  'PERFURADOR DE IBM',
  'PERFURADOR DE POCOS (EXCETO PETROLEO)',
  'PERFURADOR SEM ESPECIFICACAO',
  'PERITO CONTABIL',
  'PERITO CRIMINAL',
  'PESADOR',
  'PESCADOR ARTESANAL',
  'PESCADOR DE ALGAS',
  'PESCADOR INDUSTRIAL',
  'PESCADOR SEM ESPECIFICACAO',
  'PESPONTADEIRA DE CALCADOS',
  'PESPONTADEIRA DE COURO (EXCETO CALCADOS)',
  'PESPONTADEIRA SEM ESPECIFICACAO',
  'PESPONTADOR DE CALCADOS',
  'PESPONTADOR DE COURO (EXCETO CALCADOS)',
  'PESPONTADOR SEM ESPECIFICACAO',
  'PESQUISADOR (CURSO SUPERIOR)',
  'PIACAVEIRO',
  'PESSOAS EM BUSCA DE SEU PRIMEIRO EMPREGO',
  'PIANISTA',
  'PICOTADOR DE CARTOES JACQUARD',
  'PIEZOMETRISTA',
  'PIGMENTADOR PLASTICO (CURSO SUPERIOR)',
  'PIGMENTADOR PLASTICO (SEM CURSO SUPERIOR)',
  'PILOTO CIVIL',
  'PILOTO COMERCIAL',
  'PILOTO DE AEROBARCO',
  'PILOTO DE AVIACAO',
  'PILOTO DE CORRIDA',
  'PILOTO DE NAVEGACAO MARITIMA E INTERIOR',
  'PINCADOR',
  'PINCELEIRO',
  'PINDOBADOR DE FUMO',
  'PINEIRO',
  'PINTOR ARTISTICO',
  'PINTOR DE AVIOES',
  'PINTOR DE CERAMICA',
  'PINTOR DE CONSTRUCOES CENICAS',
  'PINTOR DE EDIFICIOS',
  'PINTOR DE EMBARCACOES',
  'PINTOR DE ESTRUTURAS METALICAS',
  'PINTOR DE IMERSAO (VEICULOS)',
  'PINTOR DE LETREIROS',
  'PINTOR DE MANUTENCAO',
  'PINTOR DE MOVEIS',
  'PINTOR DE OBRAS',
  'PINTOR DE ROTULOS',
  'PINTOR DE VEICULOS',
  'PINTOR INDUSTRIAL',
  'PINTOR POR IMERSAO',
  'PINTOR SEM ESPECIFICACAO (SEM CURSO SUPERIOR)',
  'PIPOQUEIRO',
  'PIROMETRISTA',
  'PISCICULTOR',
  'PISTOLADOR',
  'PIZZAIOLO',
  'PLAINADOR AJUSTADOR',
  'PLAINADOR DE MADEIRA',
  'PLAINADOR DE METAIS',
  'PLAINADOR FERRAMENTEIRO',
  'PLAINADOR MECANICO',
  'PLAINADOR SEM ESPECIFICACAO',
  'PLAINEIRO (MADEIRA)',
  'PLANCHADOR DE CALCADOS',
  'PLANCHADOR SEM ESPECIFICACAO',
  'PLANEJADOR DE PRODUCAO',
  'PLANEJADOR SEM ESPECIFICACAO',
  'PLANIFICADOR SEM ESPECIFICACAO',
  'PLAQUEIRO',
  'PLASTIFICADOR',
  'PLATAFORMISTA (PETROLEO)',
  'PLATINADOR',
  'PLISSADOR DE PAPEL',
  'PNEUMOTISIOLOGISTA',
  'POCEIRO',
  'PODADOR (AGRICOLA)',
  'POLICIA CIVIL',
  'POLICIA MILITAR',
  'POLICIA RODOVIARIO',
  'POLICIA SEM ESPECIFICACAO',
  'POLIDOR DE CALCADOS',
  'POLIDOR DE FIEIRAS',
  'POLIDOR DE METAIS',
  'POLIDOR DE PECAS DE METAL',
  'POLIDOR DE PEDRAS',
  'POLIDOR DE PEDRAS PRECIOSAS',
  'POLIDOR DE PRODUTOS PLASTICOS',
  'POLIDOR DE RODAS DENTADAS',
  'POLIDOR DE VIDROS E CRISTAIS',
  'POLIDOR OPTICO',
  'POLIDOR SEM ESPECIFICACAO',
  'POLITICOLOGO',
  'PONTEADOR',
  'PONTEIRO',
  'PONTILHEIRO (MATADOURO)',
  'PORTALO',
  'PORTEIRO (FORA EXCECOES)',
  'PORTEIRO DE CLUBE',
  'PORTEIRO DE HOTEL',
  'PORTEIRO DE LOCAIS DE DIVERSAO',
  'PORTEIRO SEM ESPECIFICACAO',
  'PORTUARIO',
  'POSTALISTA',
  'POSTEIRO',
  'POSTICEIRA',
  'PRACISTA',
  'PRAIEIRO (PECUARIA)',
  'PRANCHADOR SEM ESPECIFICACAO',
  'PRATEADOR DE ESPELHOS',
  'PRATEADOR DE METAIS',
  'PRATICO DE ENFERMAGEM',
  'PRATICO DE FARMACIA',
  'PRATICO DE PORTO',
  'PREFEITO',
  'PREGADOR DE BOTOES',
  'PREGADOR DE CAIXAS',
  'PREGADOR DE PALMILHAS',
  'PREGADOR DE PELES',
  'PREGADOR DE SALTOS',
  'PRELISTA',
  'PRENDAS DOMESTICAS',
  'PRENSADOR DE COUROS',
  'PRENSADOR DE FRUTAS (EXCETO OLEOGINOSAS)',
  'PRENSADOR DE LIXAS',
  'PRENSADOR DE OLEOGINOSAS',
  'PRENSEIRO DE FARINHA',
  'PRENSISTA DE AGLOMERADOS',
  'PRENSISTA DE BORRACHA',
  'PRENSISTA DE CACAU',
  'PRENSISTA DE COMPENSADOS',
  'PRENSISTA DE MADEIRA',
  'PRENSISTA DE MAMONA',
  'PRENSISTA DE METAL',
  'PRENSISTA DE PLASTICO',
  'PRENSISTA FORJADOR',
  'PRENSISTA SEM ESPECIFICACAO',
  'PREPARADOR DE AGLOMERANTES',
  'PREPARADOR DE ALIMENTOS CONCENTRADOS',
  'PREPARADOR DE AMOSTRAS DE CARVAO,ACO E GUSA',
  'PREPARADOR DE AMOSTRAS DE MINERIOS',
  'PREPARADOR DE AREIA PARA FUNDICAO',
  'PREPARADOR DE BARBOTINA',
  'PREPARADOR DE CALCADOS',
  'PREPARADOR DE CARGA',
  'PREPARADOR DE COLA',
  'PREPARADOR DE CORTE SEM ESPECIFICACAO',
  'PREPARADOR DE COUROS E PELES CURTIDAS',
  'PREPARADOR DE DADOS',
  'PREPARADOR DE EMBALAGENS',
  'PREPARADOR DE ESMALTES (CERAMICA)',
  'PREPARADOR DE ESTENCEIS (SERIGRAFIA)',
  'PREPARADOR DE ESTRUTURAS DE ACO',
  'PREPARADOR DE ESTRUTURAS METALICAS',
  'PREPARADOR DE FIBRAS ARTIFICIAIS',
  'PREPARADOR DE FRESADORA',
  'PREPARADOR DE GLICOSE',
  'PREPARADOR DE GOMA',
  'PREPARADOR DE GOMA (MADEIRA)',
  'PREPARADOR DE INGREDIENTES NAO ESPECIFICADOS',
  'PREPARADOR DE LEITE CONDENSADO',
  'PREPARADOR DE LINGOTEIRAS',
  'PREPARADOR DE MAQUINAS-FERRAMENTAS EM GERAL',
  'PREPARADOR DE MASSA (ABRASIVOS)',
  'PREPARADOR DE MASSA (ARGILA)',
  'PREPARADOR DE MASSA (DE VIDRO)',
  'PREPARADOR DE MASSA ALIMENTICIA',
  'PREPARADOR DE MASSA REFRATARIA',
  'PREPARADOR DE MASSA SEM ESPECIFICACAO',
  'PREPARADOR DE MELADO E ESSENCIA (FUMO)',
  'PREPARADOR DE MISCELANEA (RACOES BALANCEADAS)',
  'PREPARADOR DE MOLDES (CERAMICA)',
  'PREPARADOR DE MOLDES (FUNDICAO)',
  'PREPARADOR DE MOLDES NAO ESPECIFICADOS',
  'PREPARADOR DE PAINEIS DE PLASTICO',
  'PREPARADOR DE PIGMENTOS',
  'PREPARADOR DE PRANCHAS DE IMPRESSAO BRAILLE',
  'PREPARADOR DE PRENSA',
  'PREPARADOR DE RACAO NATURAL PARA GADO',
  'PREPARADOR DE RACOES',
  'PREPARADOR DE RAPE',
  'PREPARADOR DE REMEDIOS',
  'PREPARADOR DE SALTOS',
  'PREPARADOR DE SOLAS',
  'PREPARADOR DE TALO',
  'PREPARADOR DE TELAS',
  'PREPARADOR DE TINTAS',
  'PREPARADOR FISICO',
  'PREPARADOR QUIMICO',
  'PREPARADOR SEM ESPECIFICACAO',
  'PRESIDENTE DA REPUBLICA',
  'PRESIDENTE DE SINDICATO',
  'PRESTIDIGITADOR',
  'PRESUNTEIRO',
  'PRIMEIRO OFICIAL-MAQUINISTA (EMBARCACOES)',
  'PROCTOLOGISTA (CURSO SUPERIOR)',
  'PROCURADOR (TRIBUNAL MARITIMO)',
  'PROCURADOR (TRIBUNAL MILITAR)',
  'PROCURADOR AUTARQUICO',
  'PROCURADOR DA FAZENDA NACIONAL',
  'PROCURADOR DA JUSTICA',
  'PROCURADOR DE EMPRESA',
  'PROCURADOR SEM ESPECIFICACAO',
  'PRODUTOR AGRICOLA',
  'PRODUTOR AGROPECUARIO',
  'PRODUTOR CINEMATOGRAFICO',
  'PRODUTOR DE RADIO E TELEVISAO',
  'PRODUTOR DE SEMENTES',
  'PRODUTOR MUSICAL',
  'PRODUTOR PUBLICITARIO',
  'PRODUTOR SEM ESPECIFICACAO',
  'PRODUTOR TEATRAL',
  'PROFESSOR DE CEGOS',
  'PROFESSOR DE DEFICIENTES',
  'PROFESSOR DE ENSINO PRE-ESCOLAR',
  'PROFESSOR DE ENSINO SUPERIOR S/ESPECIFICACAO',
  'PROFESSOR DE FORMACAO PROFISSIONAL',
  'PROFESSOR DE IOGA',
  'PROFESSOR DE LINGUA S/ESPECIFICACAO DE GRAU',
  'PROFESSOR DE PIANO SEM ESPECIFICACAO DE GRAU',
  'PROFESSOR DE PRIMEIRO GRAU',
  'PROFESSOR DE SEGUNDO GRAU',
  'PROFESSOR DE SUPERDOTADOS',
  'PROFESSOR DE SURDOS-MUDOS',
  'PROFESSOR PARTICULAR S/ESPECIFICACAO DE GRAU',
  'PROFESSOR SEM ESPECIFICACAO',
  'PROFESSOR UNIVERSITARIO D/LINGUA E LITERATURA',
  'PROFESSOR UNIVERSITARIO DE ADMINISTRACAO',
  'PROFESSOR UNIVERSITARIO DE ANTROPOLOGIA',
  'PROFESSOR UNIVERSITARIO DE ARQUITETURA',
  'PROFESSOR UNIVERSITARIO DE ASTRONOMIA',
  'PROFESSOR UNIVERSITARIO DE BIOLOGIA',
  'PROFESSOR UNIVERSITARIO DE CIENCIAS POLITICAS',
  'PROFESSOR UNIVERSITARIO DE CONTABILIDADE',
  'PROFESSOR UNIVERSITARIO DE DEMOGRAFIA',
  'PROFESSOR UNIVERSITARIO DE DIREITO',
  'PROFESSOR UNIVERSITARIO DE ECONOMIA',
  'PROFESSOR UNIVERSITARIO DE ENFERMAGEM',
  'PROFESSOR UNIVERSITARIO DE ENGENHARIA',
  'PROFESSOR UNIVERSITARIO DE ESTATISTICA',
  'PROFESSOR UNIVERSITARIO DE FARMACOLOGIA',
  'PROFESSOR UNIVERSITARIO DE FILOSOFIA',
  'PROFESSOR UNIVERSITARIO DE FISICA',
  'PROFESSOR UNIVERSITARIO DE GEOGRAFIA',
  'PROFESSOR UNIVERSITARIO DE GEOLOGIA',
  'PROFESSOR UNIVERSITARIO DE HISTORIA',
  'PROFESSOR UNIVERSITARIO DE MATEMATICA',
  'PROFESSOR UNIVERSITARIO DE MEDICINA',
  'PROFESSOR UNIVERSITARIO DE PEDAGOGIA',
  'PROFESSOR UNIVERSITARIO DE PESQ. OPERACIONAL',
  'PROFESSOR UNIVERSITARIO DE PSICOLOGIA',
  'PROFESSOR UNIVERSITARIO DE QUIMICA',
  'PROFESSOR UNIVERSITARIO DE SOCIOLOGIA',
  'PROFESSOR UNIVERSITARIO DE TOPOGRAFIA',
  'PROGRAMADOR DE COMPUTADOR',
  'PROGRAMADOR DE MATERIAIS',
  'PROGRAMADOR DE OBRAS',
  'PROGRAMADOR DE PRODUCAO',
  'PROGRAMADOR DE SISTEMAS',
  'PROJETISTA AERONAUTICO (CURSO SUPERIOR)',
  'PROJETISTA CINEMATOGRAFICO',
  'PROJETISTA DE FERRAMENTAS',
  'PROJETISTA DE MOVEIS',
  'PROJETISTA DESENHISTA',
  'PROJETISTA ELETRICO',
  'PROJETISTA NAVAL (CURSO SUPERIOR)',
  'PROJETISTA SEM ESPECIFICACAO',
  'PROMOTOR DE VENDAS',
  'PROMOTOR PUBLICO',
  'PROPAGANDISTA',
  'PROPRIETARIO',
  'PROTETICO-DENTARIO',
  'PROTOCOLISTA',
  'PROVADOR DE CACAU',
  'PROVADOR DE CAFE',
  'PROVADOR DE CHA',
  'PROVADOR DE VINHOS E LICORES',
  'PROVADOR SEM ESPECIFICACAO',
  'PROVISTA DE CLICHERIA',
  'PROVISTA DE FOTOLITO',
  'PSICANALISTA',
  'PSICOLOGO',
  'PSICOMETRISTA (SUPERIOR OU SEGUNDO GRAU)',
  'PSIQUIATRA',
  'PUBLICISTA',
  'PUBLICITARIO SEM ESPECIFICACAO',
  'PULVERIZADOR DE LEITE EM PO',
  'PULVERIZADOR DE METAL',
  'PUXADOR DE SISAL',
  'QUADRISTA',
  'QUEIJEIRO',
  'QUIMICO (CURSO SUPERIOR)',
  'QUIMICO (SEM CURSO SUPERIOR)',
  'QUIROPODISTA',
  'QUIROPRATICO',
  'QUITANDEIRO',
  'RACHADOR DE COUROS E PELES',
  'RACHADOR DE LENHA (PARA FAZER CARVAO)',
  'RACHADOR DE LENHA SEM ESPECIFICACAO',
  'RACIONEIRO',
  'RADIALISTA SEM ESPECIFICACAO',
  'RADIATOR, RADIOATOR',
  'RADIOLOGISTA (CURSO SUPERIOR)',
  'RADIOPERADOR',
  'RADIORREPORTER',
  'RADIOTECNICO',
  'RADIOTELEFONISTA',
  'RADIOTELEGRAFISTA',
  'RADIOTELETIPISTA',
  'RAMISTA',
  'RAMULADOR',
  'RASPADOR DE MANDIOCA',
  'RASPADOR DE TACOS',
  'RASPADOR SEM ESPECIFICACAO',
  'RASQUETEADOR (METAIS)',
  'RASTREADOR DE SATELITES',
  'RASURADOR DE COUROS E PELES',
  'REBAIXADOR DE COUROS',
  'REBARBADOR DE METAIS',
  'REBARBADOR DE PRODUTOS PLASTICOS',
  'REBARBADOR SEM ESPECIFICACAO',
  'REBITADOR',
  'REBOBINADOR SEM ESPECIFICACAO',
  'RECAUCHUTADOR',
  'RECEBEDOR DE APOSTAS',
  'RECEBEDOR DE MATERIAL',
  'RECEBEDOR SEM ESPECIFICACAO',
  'RECEPCIONISTA',
  'RECOLHEDOR DE BOLAS (ESPORTE)',
  'RECORTADOR DE ESTRESIDORES DE METAL',
  'RECORTADOR DE EXCESSOS (ARTIGOS DE PELE)',
  'RECORTADOR DE SERIGRAFIA',
  'RECORTADOR DE TAPETES',
  'RECOZEDOR DE METAIS',
  'RECOZEDOR DE VIDROS',
  'RECRUTADOR DE PESSOAL',
  'RECUPERADOR DE SOLAS',
  'REDATOR',
  'REDATOR DE PUBLICIDADE',
  'REDEIRO',
  'REDEIRO DE EMBARCACAO',
  'REFILADOR',
  'REFINADOR DE OLEOS',
  'REFINADOR DE PASTA PARA PAPEL',
  'REFINADOR DE SAL',
  'REFINADOR DE TEREBENTINA',
  'REFINADOR SEM ESPECIFICACAO',
  'REFRATARISTA',
  'REFRIGERADOR DE LEITE E AFINS',
  'REGENTE (MUSICAL)',
  'REGULADOR DE AGUA',
  'REGULADOR DE PECAS',
  'REITOR',
  'RELACOES INDUSTRIAIS, GERENTE',
  'RELACOES PUBLICAS',
  'RELACOES PUBLICAS, GERENTE',
  'RELADOR DE COUROS E PELES',
  'RELIGIOSO SEM ESPECIFICACAO',
  'RELOJOEIRO',
  'REMADOR',
  'REMALHADEIRA',
  'REMALHADOR',
  'REMATADEIRA (CONFECCOES)',
  'REMENDADOR DE TAPETES',
  'REMETEDOR DE FIOS',
  'REMETINA',
  'RENDEIRO',
  'REPARADOR D/AP.TELEGRAFICOS E TELEIMPRESSORES',
  'REPARADOR DE ANTENAS',
  'REPARADOR DE APARELHOS ELETRODOMESTICOS',
  'REPARADOR DE APARELHOS ELETRONICOS',
  'REPARADOR DE APARELHOS TELEFONICOS',
  'REPARADOR DE CIRCUITOS ELETRONIC. INDUSTRIAIS',
  'REPARADOR DE HIDROMETROS',
  'REPARADOR DE INSTAL.TELEFONICAS E TELEGRAFICA',
  'REPARADOR DE MAQUINAS',
  'REPARADOR DE MOLDES SEM ESPECIFICACAO',
  'REPARADOR DE TELEVISAO',
  'REPORTER CINEGRAFISTA',
  'REPORTER FOTOGRAFICO',
  'REPORTER SEM ESPECIFICACAO',
  'REPOSITOR (COMERCIO)',
  'REPRESENTANTE COMERCIAL',
  'REPRESENTANTE DE COBRANCA',
  'REPRESENTANTE DE ENTIDADE SINDICAL',
  'REPRESENTANTE DE VENDAS',
  'REPRESENTANTE SEM ESPECIFICACAO',
  'REPRESENTANTE TECNICO',
  'REPUXADOR',
  'RESERVA DE MAQUINA',
  'RESINAS, TRABALHADOR DE',
  'RESINEIRO (COLETA)',
  'RESTAURADOR DE LIVROS E DOCUMENTOS',
  'RESTAURADOR DE OBRAS DE ARTE',
  'RETALHADOR DE CARNE',
  'RETIFICADOR (FORA EXCECOES)',
  'RETIFICADOR DE MOTORES',
  'RETIFICADOR DE VIRABREQUIM',
  'RETIFICADOR SEM ESPECIFICACAO',
  'RETILINISTA',
  'RETIREIRO',
  'RETOCADOR CROMISTA',
  'RETOCADOR DE CLICHES',
  'RETOCADOR DE NEGATIVOS',
  'RETOCADOR SEM ESPECIFICACAO',
  'RETOQUISTA',
  'RETORCEDOR DE FIBRAS',
  'RETORCEDOR DE FIOS',
  'RETRANQUISTA',
  'RETRATISTA',
  'RETROSISTA',
  'REVELADOR DE FILMES',
  'REVENDEDOR',
  'REVESTIDOR',
  'REVESTIDOR DE APARELHOS (BORRACHA)',
  'REVESTIDOR DE CILINDROS (BORRACHA)',
  'REVESTIDOR DE EDIFICACOES',
  'REVESTIDOR DE INTERIORES(PAPEL DE PLASTICO)',
  'REVESTIDOR DE MOVEIS DE MADEIRA',
  'REVESTIDOR DE PANELAS',
  'REVESTIDOR DE PAVIMENTOS CONTINUOS',
  'REVESTIDOR DE PISOS E PAREDES COM LADRILHOS',
  'REVISOR DE FILMES',
  'REVISOR DE PROVAS TIPOGRAFICAS',
  'REVISOR DE TECIDOS',
  'REVISOR DE TEXTOS',
  'REVISOR GRAFICO',
  'REVISOR SEM ESPECIFICACAO (COM FUNDAMENTAL)',
  'REVISOR SEM ESPECIFICACAO (COM SEG.GRAU)',
  'REVISTADOR (SERVICOS)',
  'RISCADEIRA',
  'RISCADOR DE CHAPAS',
  'RISCADOR DE ESTRUTURAS METALICAS',
  'RISCADOR DE METAIS',
  'RISCADOR DE ROUPAS',
  'RISCADOR MECANICO',
  'RISCADOR SEM ESPECIFICACAO',
  'ROCADEIRA',
  'RODOMOCA',
  'ROLETEADOR',
  'RONDA',
  'RONDA-DE-FORMIGA',
  'RONDANTE',
  'ROQUEIRO',
  'ROSQUEADOR',
  'ROTEIRISTA (EXCURCOES)',
  'ROTEIRISTA DE CINEMA',
  'ROTEIRISTA DE JORNAL',
  'ROTEIRISTA DE RADIO E TELEVISAO',
  'ROTEIRISTA SEM ESPECIFICACAO',
  'ROTISSEUR',
  'ROTULADOR',
  'ROUPEIRO',
  'SABOEIRO',
  'SACERDOTE',
  'SACRISTAO',
  'SALAMEIRO',
  'SALGADOR DE ALIMENTOS',
  'SALGADOR DE QUEIJOS',
  'SALGADOR SEM ESPECIFICACAO',
  'SALINEIRO',
  'SALSICHEIRO',
  'SALVA-VIDAS',
  'SANGRADOR',
  'SAPATEIRO',
  'SAQUEIRO',
  'SCHEDULER',
  'SECADOR DE ARROZ',
  'SECADOR DE CACAU',
  'SECADOR DE FOTOGRAFIA',
  'SECADOR DE FUMO',
  'SECADOR DE LA',
  'SECADOR DE MADEIRA',
  'SECADOR DE PEIXE',
  'SECADOR DE PRODUTOS QUIMICOS',
  'SECADOR DE TECIDOS',
  'SECRETARIO (DIPLOMACIA)',
  'SECRETARIO (FORA EXCECOES)',
  'SECRETARIO DE REDACAO',
  'SECRETARIO SEM ESPECIFICACAO',
  'SECURITARIO SEM ESPECIFICACAO',
  'SEGURANCA',
  'SELECIONADOR DE CALCADOS',
  'SELECIONADOR DE COLHEITAS',
  'SELECIONADOR DE CORRESPONDENCIA',
  'SELECIONADOR DE PEDRAS',
  'SELECIONADOR DE PESSOAL',
  'SELECIONADOR SEM ESPECIFICACAO',
  'SELEIRO',
  'SEM PROFISSAO',
  'SENADOR',
  'SEPARADOR DE CORRESPONDENCIA',
  'SEPARADOR DE ESTOQUE',
  'SEPARADOR DE MERCADORIAS',
  'SEPARADOR DE MINERIOS',
  'SEPARADOR DE PEDIDOS',
  'SEPARADOR DE PRODUTOS',
  'SERICULTOR',
  'SERIGRAFICO',
  'SERIGRAFISTA',
  'SERINGUEIRO',
  'SERRADOR DE ADUELAS E BARRIS',
  'SERRADOR DE ARVORES',
  'SERRADOR DE BORDAS',
  'SERRADOR DE CHIFRES',
  'SERRADOR DE FITA',
  'SERRADOR DE MADEIRA',
  'SERRADOR DE PEDRAS',
  'SERRADOR SEM ESPECIFICACAO',
  'SERRALHEIRO (FORA EXCECAO)',
  'SERRALHEIRO DE FECHADURAS',
  'SERRALHEIRO MECANICO',
  'SERRALHEIRO SEM ESPECIFICACAO',
  'SERVENTE COMERCIAL',
  'SERVENTE DE COZINHA',
  'SERVENTE DE LIMPEZA',
  'SERVENTE DE OBRAS',
  'SERVENTE DE PEDREIRO',
  'SERVENTE DE PRODUCAO',
  'SERVENTE HOSPITALAR',
  'SERVENTE SEM ESPECIFICACAO',
  'SERVENTE SEM ESPECIFICACAO (FEMININO)',
  'SERVENTUARIO DA JUSTICA',
  'SERVICOS GERAIS (SEXO FEMININO)',
  'SERVICOS GERAIS (SEXO MASCULINO)',
  'SERVIDOR DA JUSTICA',
  'SEXADOR',
  'SIDERURGICO',
  'SIDERURGICO SEM ESPECIFICACAO',
  'SINALEIRO (FERROVIAS)',
  'SINALEIRO SEM ESPECIFICACAO',
  'SINDICO',
  'SINGERISTA',
  'SITIANTE',
  'SOCIOLOGO',
  'SOCORRISTA',
  'SOLADOR',
  'SOLDADO',
  'SOLDADOR EM GERAL',
  'SONDADOR (FORA EXCECOES)',
  'SONDADOR DE PETROLEO E GAS',
  'SONDADOR DE XISTO',
  'SONDADOR SEM ESPECIFICACAO',
  'SONOPLASTA',
  'SONOTECNICO',
  'SOPRADOR DE FORNOS METALICOS',
  'SOPRADOR DE VIDORS',
  'SOROLOGISTA',
  'SORVETEIRO (AMBULANTE)',
  'SUCATEIRO',
  'SUINOCULTOR',
  'SULFACAGISTA',
  'SULFATADOR',
  'SULFITADOR (ACUCAR)',
  'SUPERINT.DE APROVISIONAMENTO(NAVEG.MAR.E INT)',
  'SUPERINTENDENTE COMERCIAL',
  'SUPERINTENDENTE DE ARMAMENTO (EMBARCACOES)',
  'SUPERINTENDENTE DE FABRICACAO',
  'SUPERINTENDENTE FINANCEIRO',
  'SUPERINTENDENTE INDUSTRIAL',
  'SUPERINTENDENTE SEM ESPECIFICACAO',
  'SUPERINTENDENTE TECNICO (EMBARCACOES)',
  'SUPERVISOR COMERCIAL',
  'SUPERVISOR DE COMPRAS',
  'SUPERVISOR DE ESCRITORIO',
  'SUPERVISOR DE LABORATORIO SEM ESPECIFICACAO',
  'SUPERVISOR DE LINHA',
  'SUPERVISOR DE MANUTENCAO',
  'SUPERVISOR DE OBRAS',
  'SUPERVISOR DE PRODUCAO',
  'SUPERVISOR DE SEGURANCA',
  'SUPERVISOR DE SEGURANCA DO TRABALHO',
  'SUPERVISOR DE SEGURANCA INDUSTRIAL',
  'SUPERVISOR DE SEGURANCA PATRIMONIAL',
  'SUPERVISOR DE SERVICOS',
  'SUPERVISOR DE VENDAS',
  'SUPERVISOR DE VOO',
  'SUPERVISOR EDUCACIONAL',
  'SUPERVISOR FLORESTAL',
  'SUPERVISOR SEM ESPECIFICACAO',
  'TABELIAO',
  'TAIFEIRO',
  'TAIPEIRO',
  'TALHADOR DE CARNE',
  'TAMANQUEIRO',
  'TANGERINO',
  'TANOEIRO',
  'TAPECEIRO (FABRICACAO\\\0',
  'TAPECEIRO (FORA EXCECAO)',
  'TAPECEIRO SEM ESPECIFICACAO',
  'TAQUEIRO',
  'TAQUIGRAFO',
  'TAREFEIRO (AGRICULTURA)',
  'TAREFEIRO SEM ESPECIFICACAO',
  'TARRAFEADOR',
  'TATUADOR (PECUARIA)',
  'TAXIDERMISTA',
  'TAXISTA',
  'TECELAO (FORA EXCECOES)',
  'TECELAO DE MALHARIA',
  'TECELAO DE MEIAS',
  'TECELAO SEM ESPECIFICACAO',
  'TECLADISTA',
  'TECLEADOR',
  'TECNICO AEROFOTOGRAFICO',
  'TECNICO AGRICOLA',
  'TECNICO AGROPECUARIO',
  'TECNICO COOPERATIVISTA',
  'TECNICO DE ABASTECIMENTO DE AGUA',
  'TECNICO DE ADMINISTRACAO',
  'TECNICO DE AERONAVES',
  'TECNICO DE AGRIMENSURA',
  'TECNICO DE ALIMENTOS',
  'TECNICO DE ANALISES CLINICAS',
  'TECNICO DE APARELHOS HOSPITALARES',
  'TECNICO DE ARTES GRAFICAS',
  'TECNICO DE ATLETISMO',
  'TECNICO DE AUTOMOVEIS',
  'TECNICO DE AVIACAO',
  'TECNICO DE BANCO DE SANGUE',
  'TECNICO DE BIOQUIMICA',
  'TECNICO DE CALCADOS',
  'TECNICO DE CALDEIRARIA',
  'TECNICO DE CALEFACAO',
  'TECNICO DE CARNES',
  'TECNICO DE CELULOSE E PAPEL',
  'TECNICO DE CENSURA   ICAS',
  'TECNICO DE CERAMICA',
  'TECNICO DE COMERCIALIZACAO',
  'TECNICO DE COMERCIO EXTERIOR',
  'TECNICO DE COMPRAS',
  'TECNICO DE COMPUTACAO',
  'TECNICO DE COMUNICACAO',
  'TECNICO DE COMUTACAO',
  'TECNICO DE CONCRETO',
  'TECNICO DE CONSTRUCAO',
  'TECNICO DE CONTABILIDADE',
  'TECNICO DE CONTROLE E QUALIDADE',
  'TECNICO DE CURTUME',
  'TECNICO DE CUSTOS',
  'TECNICO DE DESENVOLVIMENTO DE PESSOAL',
  'TECNICO DE DESENVOLVIMENTO DE PRODUTOS',
  'TECNICO DE ECONOMIA DOMESTICA',
  'TECNICO DE EDIFICACOES',
  'TECNICO DE EDUCACAO',
  'TECNICO DE ELETRICIDADE',
  'TECNICO DE ELETROCARDIOGRAMA',
  'TECNICO DE ELETROENCEFALOGRAMA',
  'TECNICO DE ELETROMECANICA',
  'TECNICO DE ELETRONICA',
  'TECNICO DE ENFERMAGEM (CURSO SUPERIOR)',
  'TECNICO DE ENGENHARIA CIVIL',
  'TECNICO DE ESMALTACAO',
  'TECNICO DE ESPECIFICACAO E PADRONIZACAO',
  'TECNICO DE ESPORTES',
  'TECNICO DE ESTATISTICA',
  'TECNICO DE ESTRADAS',
  'TECNICO DE FARMACIA',
  'TECNICO DE FOGOES',
  'TECNICO DE FORMACAO PROFISSIONAL',
  'TECNICO DE FUNDICAO',
  'TECNICO DE GALVANIZACAO',
  'TECNICO DE GEOFISICA',
  'TECNICO DE GEOLOGIA',
  'TECNICO DE HEMOTERAPIA',
  'TECNICO DE HIDRAULICA',
  'TECNICO DE HIDROGRAFIA',
  'TECNICO DE HIGIENE E SAUDE PUBLICA',
  'TECNICO DE HOTELARIA',
  'TECNICO DE IDIOMA (SEGUNDO GRAU)',
  'TECNICO DE IMPORTACAO E EXPORTACAO',
  'TECNICO DE INSTRUMENTACAO',
  'TECNICO DE LABORATORIO INDUSTRIAL',
  'TECNICO DE LABORATORIO MEDICO',
  'TECNICO DE LABORATORIO SEM ESPECIFICACAO',
  'TECNICO DE LAMINACAO',
  'TECNICO DE LATICINIOS',
  'TECNICO DE MANUTENCAO DE AERONAVES',
  'TECNICO DE MANUTENCAO DE COMPUTADOR',
  'TECNICO DE MANUTENCAO DE MAQUINAS',
  'TECNICO DE MANUTENCAO ELETRICA',
  'TECNICO DE MANUTENCAO ELETRONICA',
  'TECNICO DE MANUTENCAO MECANICA',
  'TECNICO DE MANUTENCAO SEM ESPECIFICACAO',
  'TECNICO DE MAQUINAS',
  'TECNICO DE MEDICOES',
  'TECNICO DE METALIZACAO',
  'TECNICO DE METALOGRAFIA E PETROGRAFIA',
  'TECNICO DE METEOROLOGIA',
  'TECNICO DE MICROBIOLOGIA',
  'TECNICO DE MICROFILMAGEM',
  'TECNICO DE MINAS',
  'TECNICO DE MONTAGEM',
  'TECNICO DE MONTAGEM DE TELECOMUNICACOES',
  'TECNICO DE NUTRICAO',
  'TECNICO DE OLEODUTOS',
  'TECNICO DE ORGANIZACAO E METODOS',
  'TECNICO DE ORGANIZACAO INDUSTRIAL',
  'TECNICO DE ORIENTACAO PROFISSIONAL',
  'TECNICO DE ORTOPEDIA',
  'TECNICO DE PECUARIA',
  'TECNICO DE PESCA',
  'TECNICO DE PISCICULTURA',
  'TECNICO DE PLANEJAMENTO',
  'TECNICO DE PLASTICOS',
  'TECNICO DE PREVENCAO DE INCENDIOS',
  'TECNICO DE PROCESSAMENTO DE DADOS',
  'TECNICO DE PROCESSOS',
  'TECNICO DE PRODUCAO',
  'TECNICO DE PROJETOS',
  'TECNICO DE PROTESE ORTOPEDICA',
  'TECNICO DE QUALIDADE',
  'TECNICO DE RADIACOES',
  'TECNICO DE RADIO E TELEVISAO',
  'TECNICO DE RADIOISOTOPOS',
  'TECNICO DE RADIOLOGIA MEDICA',
  'TECNICO DE RADIOTERAPIA',
  'TECNICO DE RAIOS X',
  'TECNICO DE REABILITACAO',
  'TECNICO DE REFRIGERACAO',
  'TECNICO DE SANEAMENTO',
  'TECNICO DE SEGURANCA DO TRABALHO',
  'TECNICO DE SEGURANCA INDUSTRIAL',
  'TECNICO DE SEGUROS',
  'TECNICO DE SELECAO PROFISSIONAL',
  'TECNICO DE SERICICULTURA',
  'TECNICO DE SOM',
  'TECNICO DE TECELAGEM',
  'TECNICO DE TELECOMUNICACOES',
  'TECNICO DE TELEFONIA',
  'TECNICO DE TELEIMPRESSOR',
  'TECNICO DE TEMPOS E METODOS',
  'TECNICO DE TINTURARIA (FIACAO E TECELAGEM)',
  'TECNICO DE TOPOGRAFIA',
  'TECNICO DE TRAFEGO',
  'TECNICO DE TRANSACOES IMOBILIARIAS',
  'TECNICO DE TRANSMISSAO',
  'TECNICO DE TREFILACAO',
  'TECNICO DE TRIBUTOS',
  'TECNICO DE TUBULACOES',
  'TECNICO DE TURISMO',
  'TECNICO DE VENDAS',
  'TECNICO DE VETERINARIA',
  'TECNICO DE VIDROS',
  'TECNICO DE VULCANIZACAO',
  'TECNICO DENTARIO',
  'TECNICO ELETRICISTA',
  'TECNICO ELETROMECANICO',
  'TECNICO ELETRONICO',
  'TECNICO EM AGRONOMIA',
  'TECNICO EM ASSUNTOS FISCAIS E CONTABEIS',
  'TECNICO EM BIOLOGIA',
  'TECNICO EM BIOQUIMICA',
  'TECNICO EM CIENCIAS FISICAS',
  'TECNICO EM COMUNICACOES VISUAIS',
  'TECNICO EM COURO',
  'TECNICO EM EDUCACAO',
  'TECNICO EM FINANCAS',
  'TECNICO EM HIGIENE E SEGURANCA DO TRABALHO',
  'TECNICO EM MONTAGEM',
  'TECNICO EM REBAIXAMENTO',
  'TECNICO EM RECURSOS HUMANOS',
  'TECNICO EM TRAFEGO RODOVIARIO',
  'TECNICO EM TRANSPORTES',
  'TECNICO ESPORTIVO',
  'TECNICO FERRAMENTAL',
  'TECNICO FLORESTAL',
  'TECNICO GRAFICO',
  'TECNICO IMOBILIARIO',
  'TECNICO INSTRUMENTISTA',
  'TECNICO LABORATORISTA',
  'TECNICO MADEIREIRO',
  'TECNICO MECANICO',
  'TECNICO METALURGICO',
  'TECNICO NAVAL',
  'TECNICO PROJETISTA',
  'TECNICO QUIMICO',
  'TECNICO REDATOR',
  'TECNICO SEM ESPECIFICACAO',
  'TECNICO TEXTIL',
  'TECNICOINDUSTRIAL SEM ESPECIFICACAO',
  'TECNOLOGO DE ALIMENTOS (CURSO SUPERIOR)',
  'TECNOLOGO SEM ESPECIFICACAO',
  'TELEATOR',
  'TELEFONISTA',
  'TELEGRAFISTA',
  'TELEIMPRESSOR',
  'TELETIPISTA',
  'TELHADOR',
  'TEMPERADOR DE METAIS',
  'TEMPERADOR DE VIDROS',
  'TERAPEUTA OCUPACIONAL',
  'TERREIRISTA DE CAFE',
  'TESOUREIRO',
  'TESTADOR DE CIRCUITOS',
  'TESTADOR DE FIOS (TECIDOS)',
  'TESTADOR DE MOTORES',
  'TESTADOR SEM ESPECIFICACAO',
  'TEXTURIZADOR',
  'TIJOLEIRO',
  'TIMONEIRO',
  'TINGIDOR DE COUROS E PELES',
  'TINGIDOR DE FIOS E TECIDOS',
  'TINTUREIRO (FORA EXCECOES)',
  'TINTUREIRO DE FIBRAS',
  'TINTUREIRO DE FIOS',
  'TINTUREIRO SEM ESPECIFICACAO',
  'TIPOGRAFO',
  'TIRADOR DE LEITE',
  'TITEREIRO',
  'TITULADOR',
  'TITULEIRO',
  'TONELEIRO',
  'TOPOGRAFO',
  'TORCEDOR DE MECHAS',
  'TORCEDOR DE URDIMENTO',
  'TOREIRO',
  'TORNEIRO (FORA EXCECOES)',
  'TORNEIRO CERAMISTA',
  'TORNEIRO COPIADOR',
  'TORNEIRO DE MADEIRA',
  'TORNEIRO DE PEDRA',
  'TORNEIRO REVISOR',
  'TORNEIRO REVOLVER',
  'TORNEIRO SEM ESPECIFICACAO',
  'TORRADOR DE CACAU',
  'TORRADOR DE CAFE',
  'TORRADOR SEM ESPECIFICACAO',
  'TORRISTA (PETROLEO)',
  'TOSADOR',
  'TOSQUIADOR',
  'TOXICOLOGISTA',
  'TRAB.DA EXPLORACAO DE BABACU',
  'TRAB.DA EXPLORACAO DE CARNAUBA',
  'TRAB.DA EXPLORACAO DE CASTANHA-DO-PARA',
  'TRAB.DA EXPLORACAO DE ERVA-MATE',
  'TRAB.DA EXPLORACAO DE GUARANA',
  'TRAB.EXP.ESPECIES PROD.SUBST.AROM.MED.E TOXIC',
  'TRAB.EXP.OUTRAS ESP.PROD.FIBRAS,CERAS E OLEO',
  'TRAB.EXP.OUTRAS ESP.PROD.SUBST.ALIMENTICIAS',
  'TRABAL.CULT.PLANTAS PROD.SUBST.AROM.MED.E TOX',
  'TRABAL.DA CULT.PLANTAS OLEAGINOSAS EM GERAL',
  'TRABALHADOR BRACAL (AGRICULTURA)',
  'TRABALHADOR BRACAL SEM ESPECIFICACAO',
  'TRABALHADOR DA CULT.D/OUTRAS PLANTAS FIBROSAS',
  'TRABALHADOR DA CULTURA DE ABACAXI',
  'TRABALHADOR DA CULTURA DE ALFACE',
  'TRABALHADOR DA CULTURA DE ALGODAO',
  'TRABALHADOR DA CULTURA DE AMENDOIM',
  'TRABALHADOR DA CULTURA DE ARROZ',
  'TRABALHADOR DA CULTURA DE BANANA',
  'TRABALHADOR DA CULTURA DE BATATA-INGLESA',
  'TRABALHADOR DA CULTURA DE CAJU',
  'TRABALHADOR DA CULTURA DE CEBOLA',
  'TRABALHADOR DA CULTURA DE COCO',
  'TRABALHADOR DA CULTURA DE DENDE',
  'TRABALHADOR DA CULTURA DE ERVILHA',
  'TRABALHADOR DA CULTURA DE FEIJAO',
  'TRABALHADOR DA CULTURA DE JUTA',
  'TRABALHADOR DA CULTURA DE LARANJA',
  'TRABALHADOR DA CULTURA DE LENTILHA',
  'TRABALHADOR DA CULTURA DE MAMONA',
  'TRABALHADOR DA CULTURA DE MANDIOCA',
  'TRABALHADOR DA CULTURA DE MANGA',
  'TRABALHADOR DA CULTURA DE MILHO',
  'TRABALHADOR DA CULTURA DE OUTRAS GRAMINEAS',
  'TRABALHADOR DA CULTURA DE OUTRAS HORTALICAS',
  'TRABALHADOR DA CULTURA DE OUTROS CITRICOS',
  'TRABALHADOR DA CULTURA DE PESSEGO',
  'TRABALHADOR DA CULTURA DE RAMI',
  'TRABALHADOR DA CULTURA DE ROSAS',
  'TRABALHADOR DA CULTURA DE SISAL',
  'TRABALHADOR DA CULTURA DE SOJA',
  'TRABALHADOR DA CULTURA DE TRIGO',
  'TRABALHADOR DA CULTURA DE UVA',
  'TRABALHADOR DA FLORICULTURA EM GERAL',
  'TRABALHADOR DA FRUTICULTURA EM GERAL',
  'TRABALHADOR DE OCUPACAO NAO IDENTIFICADA',
  'TRABALHADOR HORTIGRANJEIRO EM GERAL',
  'TRABALHADOR VOLANTE',
  'TRACADOR (CERAMICA)',
  'TRACADOR DE CALDEIRARIA',
  'TRACADOR DE ESTRUTURAS METALICAS',
  'TRACADOR DE MARCENARIA',
  'TRACADOR DE METAIS',
  'TRACADOR DE PEDRAS',
  'TRACADOR DE ROUPAS',
  'TRACADOR DE VIDROS E CRISTAIS',
  'TRACADOR MECANICO',
  'TRACADOR SEM ESPECIFICACAO',
  'TRADUTOR',
  'TRAMISTA',
  'TRANSADOR DE FIBRAS',
  'TRANSPORTADOR DE ARVORES',
  'TRANSPORTADOR DE ESPULAS',
  'TRANSPORTADOR DE FIOS',
  'TRANSPORTADOR DE JORNAL',
  'TRANSPORTADOR DE LEITE',
  'TRANSPORTADOR DE MACAROCAS',
  'TRANSPORTADOR DE MADEIRA',
  'TRANSPORTADOR DE TECIDOS',
  'TRANSPORTADOR LITOGRAFICO',
  'TRANSPORTADOR SEM ESPECIFICACAO',
  'TRANSVASADOR DE VINHO',
  'TRAPEIRO (PASTA DE PAPEL)',
  'TRAPEZISTA',
  'TRAPICHEIRO (CANA)',
  'TRATADOR DE AGUA',
  'TRATADOR DE ANIMAIS EM ZOOLOGICO',
  'TRATADOR DE METAIS',
  'TRATADOR SEM ESPECIFICACAO',
  'TRATORISTA (FORA EXCECOES)',
  'TRATORISTA AGRICOLA',
  'TRATORISTA FLORESTAL',
  'TRATORISTA SEM ESPECIFICACAO',
  'TREFILADOR DE BORRACHA',
  'TREFILADOR DE METAIS PRECIOSOS',
  'TREFILADOR SEM ESPECIFICACAO',
  'TREINADOR DE CAVALOS',
  'TRICICLISTA',
  'TRICOTEIRO',
  'TRIGADOR AGRICOLA',
  'TRINCHADOR',
  'TRIPEIRO (VENDAS)',
  'TRITURADOR DE MADEIRA',
  'TRITURADOR DE PEDRAS',
  'TROCADOR (TRANSPORTES)',
  'TROCADOR DE ACESSORIOS',
  'TROPEIRO',
  'TRUQUEIRO (FERROVIAS)',
  'TUPIEIRO',
  'TUPISTA',
  'TURBINEIRO (ACUCAR)',
  'TURISTOLOGO (CURSO SUPERIOR)',
  'TURISTOLOGO (SEGUNDO GRAU)',
  'TURMEIRO (AGRICULTURA)',
  'UMUDECEDOR DE FUMO',
  'URBANISTA (CURSO SUPERIOR)',
  'URDIDOR',
  'URDITRIZ',
  'URULOGISTA',
  'VACINADOR',
  'VAGALUME',
  'VAGONETEIRO',
  'VAPORIZADOR (TECIDOS)',
  'VAQUEIRO',
  'VARREDOR DE RUAS',
  'VASSOUREIRO',
  'VAZADOR DE CERAMICA',
  'VAZADOR SEM ESPECIFICACAO',
  'VELEIRO (VELAS DE CERA)',
  'VELEIRO (VELAS DE PANO)',
  'VENDEDOR (FORA EXCECOES)',
  'VENDEDOR AMBULANTE',
  'VENDEDOR DE APOSTAS (LOTERIA ESPORTIVA,TURFE)',
  'VENDEDOR DE BILHETES',
  'VENDEDOR DE JORNAIS',
  'VENDEDOR DE LOTERIA (AMBULANTE)',
  'VENDEDOR DE LOTERIA (ESTABELECIDO)',
  'VENDEDOR DE PASSAGENS',
  'VENDEDOR DE PEIXES',
  'VENDEDOR DE PIPOCA',
  'VENDEDOR DE PRODUTOS FARMACEUTICOS',
  'VENDEDOR DE SORVETE',
  'VENDEDOR DE SUCATA',
  'VENDEDOR DE TRIPAS E MIUDOS',
  'VENDEDOR DE VEICULOS',
  'VENDEDOR PRACISTA',
  'VENDEDOR SEM ESPECIFICACAO',
  'VENDEDOR TECNICO',
  'VENDEDOR VIAJANTE',
  'VENTRILOQUO',
  'VERDUREIRO',
  'VEREADOR',
  'VESTIARISTA',
  'VETERINARIO (CURSO SUPERIOR)',
  'VETERINARIO (SEGUNDO GRAU)',
  'VIAJANTE',
  'VIDRACEIROS (VITRAIS E VEICULOS)',
  'VIDREIRO (FORA EXCECAO)',
  'VIDREIRO ARTISTICO',
  'VIDREIRO SEM ESPECIFICACAO',
  'VIGARIO',
  'VIGIA (FORA EXCECOES)',
  'VIGIA DE PESCA ARTESANAL',
  'VIGIA DE TORRE (FLORESTAL)',
  'VIGIA SEM ESPECIFICACAO',
  'VIGILANTE BANCARIO',
  'VIGILANTE DE AEROPORTO',
  'VIGILANTE SANITARIO',
  'VIGILANTE SEM ESPECIFICACAO',
  'VINAGREIRO',
  'VINHATEIRO',
  'VIOLINISTA',
  'VIOLONCELISTA',
  'VIOLONISTA',
  'VISADEIRA SEM ESPECIFICACAO',
  'VISITADOR DE SAUDE PUBLICA',
  'VISITADOR DE SINISTROS',
  'VISITADOR DOMICILIAR',
  'VISITADOR SANITARIO',
  'VISITADOR SOCIAL',
  'VITRINISTA',
  'VIVEIRISTA',
  'VOLANTE (AGRICULTURA)',
  'VULCANIZADOR',
  'XAROPEIRO',
  'XEROCOPISTA',
  'ZELADOR (FORA EXCECOES)',
  'ZELADOR DE BOMBAS',
  'ZELADOR DE VESTIARIOS',
  'ZELADOR SEM ESPECIFICACAO',
  'ZINCADOR',
  'ZOOLOGO',
  'ZOOTECNISTA',
  'DIRETOR EXECUTIVO',
  'DIRETOR COMERCIAL',
  'AGENTE PENITENCIÁRIO',
  'ENCARREGADO ELETRICISTA DE INSTALAÇÕES',
];

const nationality = [
  'Afegã',
  'Sul-africana',
  'Albanesa',
  'Alemã',
  'Andorrana',
  'Angolana',
  'Antiguana',
  'Antilhana Holandesa',
  'Saudita',
  'Argelina',
  'Argentina',
  'Armênia',
  'Arubana',
  'Australiana',
  'Austríaca',
  'Azerbaidjana',
  'Bahamense',
  'Bengalesa',
  'Barbadiana',
  'Barenita',
  'Bielo-russa',
  'Belga',
  'Belizenha',
  'Beninense',
  'Bermudiana',
  'Boliviana',
  'Bósnia',
  'Betchuana',
  'Brasileira',
  'Bruneiana',
  'Búlgara',
  'Burquinense',
  'Burundinesa',
  'Butanesa',
  'Caboverdiana',
  'Camoronesa',
  'Cambojana',
  'Canadense',
  'Catariana',
  'Cazaque',
  'Chadiana',
  'Chilena',
  'Chinesa',
  'Cipriota',
  'Cingapuriana',
  'Colombiana',
  'Comorense',
  'Congolesa',
  'Norte-coreana',
  'Sul-coreana',
  'Marfinense',
  'Costarriquenha',
  'Croata',
  'Cubana',
  'Dinamarquesa',
  'Djibutiano',
  'Dominicana',
  'Egípcia',
  'Salvadorenha',
  'Árabe',
  'Equatoriana',
  'Eritréia',
  'Eslovaca',
  'Eslovena',
  'Espanhola',
  'Estadunidense',
  'Estoniana',
  'Etíope',
  'Russa',
  'Fijiana',
  'Filipina',
  'Finlandesa',
  'Francesa',
  'Gabonesa',
  'Gambiana',
  'Ganense',
  'Georgiana',
  'Inglesa',
  'Granadina',
  'Grega',
  'Groelandês',
  'Guamesa',
  'Guatemalteca',
  'Guianesa',
  'Guianense',
  'Guineense',
  'Guinéu-equatoriana ',
  'Guineense',
  'Haitiana',
  'Holandesa',
  'Hondurenha',
  'Honconguesa',
  'Húngara',
  'Iemenita',
  'Norfolkiana',
  'Caimanesa',
  'Feroês',
  'Malvinense',
  'Marshallina',
  'Pitcairnesa',
  'Reunionesa',
  'Salomônica',
  'Toquelauana',
  'Turco-caiquense',
  'Virginense',
  'Virginiana',
  'Wallisiana',
  'Indiana',
  'Indonésia',
  'Iraniana',
  'Iraquiana',
  'Irlandesa',
  'Islandesa',
  'Israelense',
  'Italiana',
  'Iugoslava',
  'Jamaicana',
  'Japonsesa',
  'Jordaniana',
  'Kiribatiana',
  'Kuweitiana',
  'Laosiana',
  'Lesota',
  'Letã',
  'Libanesa',
  'Liberiana',
  'Líbia',
  'Liechtensteiniense',
  'Lituana',
  'Luxemburguesa',
  'Macedônia',
  'Malgaxe',
  'Malaia',
  'Malauiana',
  'Maldívia',
  'Malinesa',
  'Maltesa',
  'Marroquina',
  'Mauriciana',
  'Mauritana',
  'Mexicana',
  'Birbanesa',
  'Micronésia',
  'Moçambicana',
  'Moldávia',
  'Monegasca',
  'Mongol',
  'Namibiana',
  'Nauruana',
  'Nepalesa',
  'Nicaraguense',
  'Nigeriana',
  'Nigeriana',
  'Niueana',
  'Norueguesa',
  'Neocaledônia',
  'Neozelandesa',
  'Omani',
  'Palauense',
  'Panamenha',
  'Papuásia',
  'Paquistanesa',
  'Paraguaia',
  'Peruana',
  'Franco-polinésia',
  'Polonesa',
  'Porto-riqueinha',
  'Portuguesa',
  'Queniana',
  'Quirguiz',
  'Centro-africana',
  'Dominicana',
  'Tcheca',
  'Romena',
  'Ruandesa',
  'Saaráui',
  'Samoana',
  'Samoana',
  'Samarinesa',
  'Santa-lucense',
  'São-cristovense',
  'São-tomeense',
  'São-vicentina',
  'Seichelense',
  'Senegalesa',
  'Leonesa',
  'Síria',
  'Somali',
  'Cingalesa',
  'Suazi',
  'Sudanesa',
  'Suéca',
  'Suíça',
  'Surinamesa',
  'Tadjique',
  'Tailandesa',
  'Taiuanesa',
  'Tanzaniana',
  'Timorense',
  'Togolesa',
  'Tonganesa',
  'Trinitina',
  'Tunisiana',
  'Turcomana',
  'Turca',
  'Tuvaluana',
  'Ucraniana',
  'Ugandense',
  'Uruguaia',
  'Uzbeque',
  'Vanuatense',
  'Vaticana',
  'Venezuelana',
  'Vietnamita',
  'Zambiana',
  'Zimbabuana',
];

const pepOptions = [
  'Não se aplica',
  'Detentores de mandatos eletivos dos Poderes Executivo e Legislativo da União;  Natureza Especial ou equivalente;',
  'Ocupantes de cargo, no Poder Executivo da União, de: Ministro de Estado ou equiparado, ou, presidente, vice-presidente e diretor, ou equivalentes, de entidades da administração pública indireta; e ou, Grupo Direção e Assessoramento Superiores (DAS), nível 6, ou equivalente; ',
  'Membros do Conselho Nacional de Justiça, do Supremo Tribunal Federal, dos Tribunais Superiores, dos Tribunais Regionais Federais, dos Tribunais Regionais do Trabalho, dos Tribunais Regionais Eleitorais, do Conselho Superior da Justiça do Trabalho e do Conselho da Justiça Federal; ',
  'Membros do Conselho Nacional do Ministério Público, o Procurador-Geral da República, o Vice-Procurador-Geral da República, o Procurador-Geral do Trabalho, o Procurador-Geral da Justiça Militar, os Subprocuradores-Gerais da República e os Procuradores[1]Gerais de Justiça dos Estados e do Distrito Federal; ',
  'Membros do Tribunal de Contas da União, o Procurador-Geral e os Subprocuradores-Gerais do Ministério Público junto ao Tribunal de Contas da União; ',
  'Presidentes e os tesoureiros nacionais, ou equivalentes, de partidos políticos; ',
  'Governadores e os Secretários de Estado e do Distrito Federal, os Deputados Estaduais e Distritais, os presidentes, ou equivalentes, de entidades da administração pública indireta estadual e distrital e os presidentes de Tribunais de Justiça, Tribunais Militares, Tribunais de Contas ou equivalentes dos Estados e do Distrito Federal; ',
  'Prefeitos, os Vereadores, os Secretários Municipais, os presidentes, ou equivalentes, de entidades da administração pública indireta municipal e os Presidentes de Tribunais de Contas ou equivalentes dos Municípios. ',
  'Pessoas que no exterior, sejam: chefes de estado ou de governo; ou, políticos de escalões superiores; ou ocupantes de cargos governamentais de escalões superiores; ou IV - oficiais-generais e membros de escalões superiores do Poder Judiciário; ou executivos de escalões superiores de empresas públicas; ou  dirigentes de partidos políticos. ',
];

const documentOptions = [
  'Carteira de Identidade',
  'Registro Nacional de Estrangeiro - RNE',
  'Carteira de Entidade de Classe',
  'Carteira Nacional de Habilitação',
  'Passaporte',
  'Carteira de Trabalho',
  'Nova Carteira de Identdade Nacional - CIN',
];

const maritalStatus = [
  'Comunhão Universal de Bens',
  'Comunhão Parcial de Bens',
  'Separação Total de Bens',
  'Participação Final nos Aquestos',
  'Separação Obrigatória de Bens',
];

export {
  educationOptions,
  badesulRole,
  race,
  occupation,
  nationality,
  pepOptions,
  documentOptions,
  maritalStatus,
};
